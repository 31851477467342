import React, { useEffect, useState } from "react";
import { Input, Typography, Button, message, Spin, Form, Divider } from "antd";
import { get, post } from "../../api/axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const Settings = () => {
    const [settings, setSettings] = useState({
        service: "",
        "about-us": "",
        faq: "",
        policies: "",
        tax: "",
        terms: "",
    });
    const { Title } = Typography;
    const [fromloading, setFromLoading] = useState(false);

    useEffect(() => {
        get("settings").then((res) => {
            setFromLoading(true);
            const dataObj = {};
            res.data.map((item) => {
                dataObj[item.key] = item.value;
                console.log("item", item);
            });

            setSettings({ ...dataObj });
            console.log("dataObj", dataObj);
        });
    }, []);

    const handleChange = (e, name) => {
        setSettings({ ...settings, [name]: e });
    };
    const handleChangeText = (e) => {
        setSettings({ ...settings, [e.target.id]: e.target.value });
    };

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);
        console.log("settings", settings);

        const newSetting = [];
        Object.keys(settings).forEach(function (key, index) {
            const newObj = {};
            newObj.key = key;
            newObj.value = settings[key];
            newSetting.push(newObj);
        });

        post("settings/update", { settings: newSetting })
            .then((res) => {
                window.location.reload();
                message.success("تم التعديل بنجاح");
                setLoading(false);
            })
            .catch((err) => {
                message.error(err.response.data.message);
                setLoading(false);
            });
    };

    const optionsSun = {
        buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            //  '/', // Line break
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image", "video", "audio"],
            ["fullScreen", "showBlocks", "codeView"],
            ["preview", "print"],
            // ['save', 'template'],
        ],
        font: ["Arial",
            "Sans-Serif",
            'tahoma',
            'DroidArabicKufiRegular',
            "Georgia",
            "Tahoma",
            "Trebuchet MS",
            "Verdana",
            "Logical",
            "Salesforce Sans",
            "Garamond",
            "Serif",
            "Times New Roman",
            "Helvetica"],
    };

    return (
        <div>
            <Title level={2} className="text-main mb-4">
                الاعدادات
            </Title>
            {fromloading ? (
                <Form
                    onFinish={onFinish}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        service: settings.service,
                        tax: settings.tax,
                        commission: settings.commission,
                        service_tax: settings.service_tax,
                    }}
                >
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Item
                                label="رسوم الخدمة"
                                name="service"
                                onChange={handleChangeText}
                                rules={[
                                    {
                                        required: true,
                                        message: "هذا الحقل مطلوب!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="ضريبة رسوم الخدمة"
                                name="service_tax"
                                onChange={handleChangeText}
                                rules={[
                                    {
                                        required: true,
                                        message: "هذا الحقل مطلوب!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label=" العمولة"
                                name="commission"
                                onChange={handleChangeText}
                                rules={[
                                    {
                                        required: true,
                                        message: "هذا الحقل مطلوب!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="ضريبة العمولة "
                                name="tax"
                                onChange={handleChangeText}
                                rules={[
                                    {
                                        required: true,
                                        message: "هذا الحقل مطلوب!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col-lg-11 mb-5">
                            <div className="mb-5">
                                <Divider orientation="left" plain>
                                    <Title level={5} className="text-main mb-0">
                                        الشروط
                                    </Title>
                                </Divider>
                                <SunEditor
                                    defaultValue={settings.terms}
                                    showToolbar={false}
                                    onChange={(e) => handleChange(e, "terms")}
                                    setDefaultStyle="height: auto; min-height: 300px"
                                    setOptions={optionsSun}
                                />
                            </div>
                            <div className="mb-5">
                                <Divider orientation="left" plain>
                                    <Title level={5} className="text-main mb-0">
                                        عن مكان
                                    </Title>
                                </Divider>
                                <SunEditor
                                    defaultValue={settings['about-us']}
                                    showToolbar={false}
                                    onChange={(e) => handleChange(e, "about-us")}
                                    setDefaultStyle="height: auto; min-height: 300px"
                                    setOptions={optionsSun}
                                />
                            </div>
                            <div className="mb-5">
                                <Divider orientation="left" plain>
                                    <Title level={5} className="text-main mb-0">
                                        السياسات
                                    </Title>
                                </Divider>
                                <SunEditor
                                    defaultValue={settings.policies}
                                    showToolbar={false}
                                    onChange={(e) => handleChange(e, "policies")}
                                    setDefaultStyle="height: auto; min-height: 300px"
                                    setOptions={optionsSun}
                                />
                            </div>
                            <div className="mb-5">
                                <Divider orientation="left" plain>
                                    <Title level={5} className="text-main mb-0">
                                        الأسئلة الشائعة
                                    </Title>
                                </Divider>
                                <SunEditor
                                    defaultValue={settings.faq}
                                    showToolbar={false}
                                    onChange={(e) => handleChange(e, "faq")}
                                    setDefaultStyle="height: auto; min-height: 300px"
                                    setOptions={optionsSun}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-4">
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "جاري حفظ التعديل..." : "حفظ التعديل"}
                            </Button>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default Settings;
