import axios from "axios";
export const baseUrl = "https://test.book-makan.com/api/admin/";
// export const baseUrl = "https://new-makan.test/api/admin/";

const token = localStorage.getItem("tokenAdmin");
export const tokenAdmin = localStorage.getItem("tokenAdmin");

const axiosApi = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "api-key": "61e67458-888d-42b1-9dcb-bd1f89440101",
  },
});

axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axiosApi.defaults.headers.common["api-key"] =
  "61e67458-888d-42b1-9dcb-bd1f89440101";
const axiosApi1 = axios.create({
  baseURL: baseUrl,
  headers: {
    "api-key": "61e67458-888d-42b1-9dcb-bd1f89440101",
    "Content-type": "multipart/form-data",
  },
});

axiosApi1.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axiosApi1.defaults.headers.common["api-key"] =
  "61e67458-888d-42b1-9dcb-bd1f89440101";

export { axiosApi1 };
axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (
      error.message == "Request failed with status code 401" ||
      error.message == "Unauthenticated" ||
      error.response.data.message ==
        "Unauthenticated. in file:  /var/www/html/new-makan/vendor/laravel/framework/src/Illuminate/Auth/Middleware/Authenticate.php at line: 82"
    ) {
      window.location.href = "/login";
      localStorage.removeItem("tokenAdmin");
      localStorage.removeItem("authNameAdmin");
      localStorage.removeItem("loggedInAdmin");
      localStorage.removeItem("permissionsAdmin");
    } else if (
      error.response.data.errors == "Property is not found" ||
      error.response.data.message == "Property is not found" ||
      error.response.data.errors == "القيمة المحددة code غير موجودة"
    ) {
      localStorage.removeItem("codeAddProperty");
      window.location.href = "/add-property/step-1";
    } else if (error.response.data.code == 403) {
      window.location.href = "/403";
    }
    // return error
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(err => {
      // console.log("", err);
    });
}

export async function postFromData(url, data, config = {}) {
  const formData = new FormData();
  const dataList = data;
  for (let item in dataList) {
    formData.append(item, dataList[item]);
  }
  return axiosApi.post(url, formData).then(res => res.data);
}

export async function newPostFromData(url, data, config = {}) {
  const formData = new FormData();
  const arrayData = {};
  const dataList = data;
  for (let item in dataList) {
    if (item == "image") {
      formData.append("image", dataList["image"]);
    } else {
      arrayData[item] = dataList[item];
    }
  }
  const finalData = { ...arrayData };
  return axiosApi.post(url, finalData).then(res => res.data);
}

export async function post(url, data) {
  return axiosApi.post(url, data).then(res => res.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function putFromData(url, data, config = {}) {
  const formData = new FormData();
  const dataList = data;
  for (let item in dataList) {
    formData.append(item, dataList[item]);
  }
  return axiosApi.put(url, formData).then(res => res.data);
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url).then(response => {
    return response.data;
  });
}

export default axiosApi;
