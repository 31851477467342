import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { del, get, post } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";

const HomeCityTypes = () => {
  const confirm = id => {
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = e => {
    message.error("تم الغاء الامر");
  };

  const columns = [
    {
      title: "الرقم ",
      dataIndex: "id",
      render: status => <span>{status}</span>,
    },

    {
      title: " المدينة ",
      dataIndex: "city",
    },
    {
      title: "النوع",
      dataIndex: "property_type",
    },
  ];

  if (
    checkPermission("update-home-city-type") ||
    checkPermission("delete-home-city-type")
  ) {
    columns.push(
      {
        title: "تعديل",
        dataIndex: "id",
        render: id => (
          <button className="btn btn-outline-warning btn-sm p-0">
            <Link
              className="color-inherit text-decoration-none py-1 px-2 d-block"
              to={`/home-city-types/edit/${id}`}
            >
              تعديل
            </Link>
          </button>
        ),
      },
      {
        title: "حذف",
        dataIndex: "id",
        render: id => (
          <Popconfirm
            title="حذف العنصر"
            description="هل أنت متأكد من حذف هذه العنصر؟"
            onConfirm={() => confirm(id)}
            onCancel={cancel}
            okText="نعم"
            cancelText="لا"
          >
            <Button danger>حذف</Button>
          </Popconfirm>
        ),
      }
    );
  }
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("home-city-types?page=1").then(res => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const handleDelet = id => {
    del(`home-city-types/${id}`);
    let dateFilter = data.filter(item => item.id !== id);
    setdata(dateFilter);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = newSelectedRowKeys => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const handlePagination = pageNumber => {
    setLoading(true);

    get(`home-city-types?page=${pageNumber}`).then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        {checkPermission("create-home-city-type") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
              <Link
                className="text-decoration-none d-block h-100"
                to="/home-city-types/add"
              >
                <Button type="primary" block>
                {" "}
                إضافة{" "}
            </Button>
              </Link>
          </div>
        )}
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 650,
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCityTypes;
