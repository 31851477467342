import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Form,
  DatePicker,
  Spin,
} from "antd";
import { get, postFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const AddUnitRegistration = () => {
  const navigate = useNavigate();
  const [fromloading, setFromLoading] = useState(false);
  const [form] = Form.useForm();

  const [inputsValue, setinputsValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [optionsregion_id, setOptionsregion_id] = useState([]);
  const [optionsregion_idData, setOptionsregion_idData] = useState([]);
  const [optionscity_id, setOptionscity_id] = useState([]);
  const [optionscity_idData, setOptionscity_idData] = useState([]);
  const [optionsdistrict_id, setOptionsdistrict_id] = useState([]);
  const [optionsdistrict_idData, setOptionsdistrict_idData] = useState([]);
  // const [dataCity, setDataCity] = useState([]);
  // const [optionCity, setOptionCity] = useState([]);
  // useEffect(() => {
  //   setOptionCity(
  //     dataCity.map(d => ({
  //       value: d.id,
  //       label: d.name_ar,
  //     }))
  //   );
  // }, [dataCity]);

  const [dataPay_type, setDataPay_type] = useState([]);
  const [optionPay_type, setOptionPay_type] = useState([]);
  useEffect(() => {
    setOptionPay_type(
      dataPay_type.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataPay_type]);

  useEffect(() => {
    // get("get-all-cities").then(res => {
    //   setDataCity(res.data);
    // });
    get("get-all-property-types").then(res => {
      setDataPay_type(res.data);
    });
    get("get-all-regions").then(res => {
      setFromLoading(false);
      setOptionsregion_id(res.data);
    });
  }, []);



  useEffect(() => {
    setOptionsregion_idData(
      optionsregion_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionsregion_id]);
  useEffect(() => {
    setOptionscity_idData(
      optionscity_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionscity_id]);
  useEffect(() => {
    setOptionsdistrict_idData(
      optionsdistrict_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionsdistrict_id]);

  const onChangeregion_id = value => {
    form.setFieldsValue({
      district_id: '',
      city_id: '',
    });
    get(`get-cities-by-region/${value}`).then(response => {
      setOptionscity_id(response.data);
    });
  };
  const onChangecity_id = value => {
    form.setFieldsValue({
      district_id: '',
    });

    get(`get-districts-by-city/${value}`).then(response => {
      setOptionsdistrict_id(response.data);
    });
  };
  const onChangedistrict_id = value => {
    console.log("value", value);
    setinputsValue({ ...inputsValue, district_id: value });
  };

  const onFinish = values => {
    setLoading(true);
    postFromData("unit-registrations", {
      ...values,
    })
      .then(res => {
        message.success("تم الإضافة بنجاح");
        setLoading(false);
        navigate("/unit-registrations");
      })
      .catch(err => {
        message.error(err.response.data.message);
        console.log("err.errors", err.response.data.errors);
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        إضافة وحدة تسجيل جديدة{" "}
      </Title>
      {
        !fromloading ?
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 15,
            }}
            initialValues={{
              remember: true,
            }}
          >
            <div className="row">
              <div className="col-lg-4">
                <Form.Item
                  label="اسم الوحدة"
                  name="property_name"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="رقم المالك"
                  name="owner_name"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="رقم المسوق"
                  name="marketer_number"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="البريد الإلكتروني"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="رقم الجوال 1"
                  name="first_phone_number"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="رقم الجوال 2"
                  name="second_phone_number"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="واتساب  "
                  name="whatsapp_number"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="انستجرام  "
                  name="instagram_url"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="تويتر  "
                  name="twitter_url"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="سناب شات  "
                  name="snapchat_url"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="يوتيوب   "
                  name="youtube_url"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item label="ملحوظة   " name="note">
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="الاجراء"
                  name="action"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Select
                    className="w-100"
                    showSearch
                    placeholder="حدد الاجراء"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: 1,
                        label: "مسجل",
                      },
                      {
                        value: 2,
                        label: "نموذج معبأ",
                      },
                      {
                        value: 3,
                        label: "تم إرسال طلب إضافة عقار",
                      },
                      {
                        value: 4,
                        label: "تم رفض إضافة خاصية",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="مستوي الوحدة"
                  name="property_level"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Select
                    className="w-100"
                    showSearch
                    placeholder="حدد مستوي الوحدة"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: 0,
                        label: "0",
                      },

                      {
                        value: 1,
                        label: "1",
                      },
                      {
                        value: 2,
                        label: "2",
                      },
                      {
                        value: 3,
                        label: "3",
                      },
                      {
                        value: 4,
                        label: "4",
                      },
                      {
                        value: 5,
                        label: "5",
                      },
                      {
                        value: 6,
                        label: "6",
                      },
                      {
                        value: 7,
                        label: "7",
                      },
                      {
                        value: 8,
                        label: "8",
                      },
                      {
                        value: 9,
                        label: "9",
                      },
                      {
                        value: 10,
                        label: "10",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="نوع نوع الملكية"
                  name="property_type_id"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Select
                    className="w-100"
                    showSearch
                    placeholder="حدد نوع نوع الملكية"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={optionPay_type}
                  />
                </Form.Item>
              </div>

              <div className="col-lg-4">
                <Form.Item
                  label="المنطة"
                  name="region_id"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Select
                    className="w-100"
                    showSearch
                    placeholder="حدد المنطة"
                    optionFilterProp="children"
                    onChange={onChangeregion_id}
                    options={optionsregion_idData}
                    rules={[
                      {
                        required: true,
                        message: "هذا الحقل مطلوب!",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="المدينة"
                  name="city_id"
                  value={inputsValue.city_id}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Select
                    className="w-100"
                    showSearch
                    placeholder="حدد المدينة"
                    optionFilterProp="children"
                    onChange={onChangecity_id}
                    value={inputsValue.city_id}
                    // filterOption={(input, option) =>
                    //   (dataCities?.label ?? "")
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase())
                    // }
                    options={optionscity_idData}
                    rules={[
                      {
                        required: true,
                        message: "هذا الحقل مطلوب!",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  label="الحى"
                  name="district_id"
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                >
                  <Select
                    className="w-100"
                    showSearch
                    placeholder="حدد الحى"
                    optionFilterProp="children"
                    onChange={onChangedistrict_id}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={optionsdistrict_idData}
                    rules={[
                      {
                        required: true,
                        message: "هذا الحقل مطلوب!",
                      },
                    ]}
                  />
                </Form.Item>
              </div>

              <div className="col-lg-4 offset-lg-4">
                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button
                    loading={loading}
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    {loading ? "جاري الإضافة..." : "إضافة"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
          : <div className="text-center">
            <Spin size="large" />
          </div>
      }
    </div>
  );
};

export default AddUnitRegistration;
