import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { get } from "../../api/axios";
import { Pagination } from "antd";

const Offers = () => {
  const columns = [
    {
      title: " الرقم   ",
      dataIndex: "id",
    },
    {
      title: "  رقم الطلب  ",
      dataIndex: "request_number",
    },
    {
      title: "  تاريخ الطلب ",
      dataIndex: "request_date",
    },

    {
      title: "   اسم الخصم   ",
      dataIndex: "name",
    },
    {
      title: " كود الوحدة  ",
      dataIndex: "property_code",
    },
    {
      title: "  تاريخ البداية ",
      dataIndex: "start_date",
    },
    {
      title: "  تاريخ النهاية ",
      dataIndex: "end_date",
    },

    {
      title: " اليوم  ",

      render: ({ day }) => (
        <>
          {day === "thu"
            ? "الخميس"
            : day === "fri"
            ? "الجمعة"
            : day === "sat"
            ? "السبت"
            : "وسط الاسبوع"}
        </>
      ),
    },
    {
      title: "السعر",
      children: [
        {
          title: " ليلة ",
          dataIndex: "prices",
          render: ({ night }) => <>{night}</>,
        },
        {
          title: " فترة صباحية  ",
          dataIndex: "prices",
          render: ({ day_time }) => <>{day_time}</>,
        },
        {
          title: " فترة مسائية ",
          dataIndex: "prices",
          render: ({ night_time }) => <>{night_time}</>,
        },
      ],
    },
    {
      title: "  حالة الطلب ",
      render: ({ status }) => (
        <>
          <Tag color={`${status === "منتهي" ? "success" : "error"}`}>
            {status}
          </Tag>
        </>
      ),
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("get-offers").then(res => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`get-offers?page=${pageNumber}`).then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
              }}
              bordered
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
