import React, { useEffect, useState } from 'react'
import { Input, Typography, Button, message, Select, Spin, Form, } from 'antd';
import { get, put } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

const { Title } = Typography;

const EditHomeCityType = () => {
    const navigate = useNavigate();
    let { Id } = useParams();


    const [fromloading, setFromLoading] = useState(false);

    const [inputsValue, setinputsValue] = useState({ "name_ar": '', "name_en": "", "city_id": "" });
    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })
    }

    useEffect(() => {
        get(`home-city-types/${Id}`).then(res => {
            setinputsValue(res.data)
            setFromLoading(true)
        })
    }, []);


    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        put(`home-city-types/${Id}`, values).then(res => {
            message.success('تم تعديل بنجاح');
            setLoading(false);
            navigate('/home-city-types')
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading(false);
        })

    };


    const [dataCities, setDataCities] = useState([]);
    const [option, setOption] = useState([]);

    useEffect(() => {
        setOption(dataCities.map(d => ({
            "value": d.id,
            "label": d.name_ar,
        })))

    }, [dataCities]);

    const [dataProperty_type, setDataProperty_type] = useState([]);
    const [optionProperty_type, setOptionProperty_type] = useState([]);

    useEffect(() => {
        setOptionProperty_type(dataProperty_type.map(d => ({
            "value": d.id,
            "label": d.name_ar,
        })))

    }, [dataProperty_type]);

    useEffect(() => {
        get('get-all-cities').then(res => {
            setDataCities(res.data)
        })
        get('get-all-property-types').then(res => {
            setDataProperty_type(res.data)
        })
    }, []);


    const onChange = (value) => {
        console.log(`selected ${value}`);
        setinputsValue({ ...inputsValue, city_id: value })
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    return (
        <div>
            <Title level={2} className='text-main mb-4'> تعديل النوع  </Title>
            {
                fromloading ?
                    <Form
                        onFinish={onFinish}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}

                        initialValues={{
                            "city_id": inputsValue.city_id,
                            "property_type_id": inputsValue.property_type_id,
                        }}
                        autoComplete="off"
                    >
                        <div className='row'>


                        <div className='col-lg-6'>
                        <Form.Item
                            label='المدينة'
                            name='city_id'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Select
                                className='w-100'
                                showSearch
                                placeholder="حدد المدينة"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={option}
                            />

                        </Form.Item>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Item
                            label='النوع'
                            name='property_type_id'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Select
                                className='w-100'
                                showSearch
                                placeholder="حدد النوع"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={optionProperty_type}
                            />

                        </Form.Item>
                    </div>

                            <div className='col-lg-4 offset-lg-4'>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 1,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        size='large' block type="primary" htmlType="submit">
                                        {loading ? 'جاري التعديل...' : 'تعديل'}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>



                    </Form>

                    :
                    <div className='text-center'>
                        <Spin size="large" />
                    </div>
            }
        </div>
    )
}

export default EditHomeCityType
