import React, { useEffect, useState } from "react";
import { Input, Typography, Button, message, Spin, Form } from "antd";
import { get, put } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const { Title } = Typography;

const EditBank = () => {
  const navigate = useNavigate();
  let { bankId } = useParams();

  const [fromloading, setFromLoading] = useState(false);
  const [inputsValue, setinputsValue] = useState({});
  const handleChange = e => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    get(`banks/${bankId}`).then(res => {
      setinputsValue(res.data);
      setFromLoading(true);
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);

    put(`banks/${bankId}`, inputsValue)
      .then(res => {
        message.success("تم تعديل بنجاح");
        setLoading(false);
        navigate("/banks");
      })
      .catch(err => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        تعديل بنك{" "}
      </Title>
      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            name_ar: inputsValue.name_ar,
            name_en: inputsValue.name_en,
          }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                label="الاسم بالعربي"
                name="name_ar"
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="الاسم بالانجليزي"
                name="name_en"
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            {/* ))
                    } */}

            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditBank;
