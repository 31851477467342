import React, { useState, useEffect } from 'react';
import { post, postFromData } from '../../../../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import Steper from '../layoutProperty/Steper';
import { Input, Button, message, Spin, Form, Typography, Checkbox, Divider, InputNumber, Select } from 'antd';

const Step5 = () => {
    const { Paragraph } = Typography;
    const navigate = useNavigate();
    const [fromloading, setFromLoading] = useState(false);
    const [inputsValue, setinputsValue] = useState({
        "night": {
            "arrive_time": "",
            "leave_time": "",
            "thu": '',
            "fri": '',
            "sat": '',
            "rest_of_week": ''
        },
        "day_time": {
            "arrive_time": "",
            "leave_time": "",
            "thu": '',
            "fri": '',
            "sat": '',
            "rest_of_week": ''
        },
        "night_time": {
            "arrive_time": "",
            "leave_time": "",
            "thu": '',
            "fri": '',
            "sat": '',
            "rest_of_week": ''
        }
    });
    useEffect(() => {
        // return () => {

        if (localStorage.getItem('codeAddProperty')) {
            // postFromData('add-property/step-5', { code: localStorage.getItem('codeAddProperty') }).then(res => {
            //     setinputsValue(res.data)
                setFromLoading(true)
            //
            //
            //
            //     setnight(res.data.night ? true : false)
            //     setday_time(res.data.day_time ? true : false)
            //     setnight_time(res.data.night_time ? true : false)
            //
            //     if (res.data.length === 0) {
            //         setnight(true)
            //         setday_time(true)
            //         setnight_time(true)
            //
            //     }
            //
            //
            //
            // })
        } else {
            navigate('/add-property/step-1')
        }
        // }
    }, []);
    const [loading, setLoading] = useState(false);



    const [night, setnight] = useState(true);
    const [day_time, setday_time] = useState(true);
    const [night_time, setnight_time] = useState(true);

    const onChangenight = (e) => {
        setnight(e.target.checked)
    };
    const onChangeday_time = (e) => {
        setday_time(e.target.checked)
    };
    const onChangenight_time = (e) => {
        setnight_time(e.target.checked)
    };

    const onFinish = (values) => {

        // console.log('values', values);


        const type = []
        if (night) {
            type.push('night')
        }
        if (day_time) {
            type.push('day_time')
        }
        if (night_time) {
            type.push('night_time')
        }

        if (type.length === 0) {
            message.error('برجى ادخال نوعية مدة الإقامة');

        } else {
            setLoading(true);
            post('add-property/step-5', JSON.stringify({
                'code': localStorage.getItem('codeAddProperty')
                , ...values
                , type
            })).then(res => {
                setLoading(false);
                navigate('/add-property/step-6')
            }).catch(err => {
                message.error(err.response.data.message);
                setLoading(false);
            })
        }


    };


    const optionsAllTimes = [
        {
            'label': '12:00 AM',
            'value': '12:00 AM',
        },
        {
            'label': '1:00 AM',
            'value': '1:00 AM',
        },
        {
            'label': '2:00 AM',
            'value': '2:00 AM',
        },
        {
            'label': '3:00 AM',
            'value': '3:00 AM',
        },
        {
            'label': '4:00 AM',
            'value': '4:00 AM',
        },
        {
            'label': '5:00 AM',
            'value': '5:00 AM',
        },
        {
            'label': '6:00 AM',
            'value': '6:00 AM',
        },
        {
            'label': '7:00 AM',
            'value': '7:00 AM',
        },
        {
            'label': '8:00 AM',
            'value': '8:00 AM',
        },
        {
            'label': '9:00 AM',
            'value': '9:00 AM',
        },
        {
            'label': '10:00 AM',
            'value': '10:00 AM',
        },
        {
            'label': '11:00 AM',
            'value': '11:00 AM',
        },

        {
            'label': '12:00 PM',
            'value': '12:00 PM',
        },
        {
            'label': '1:00 PM',
            'value': '1:00 PM',
        },
        {
            'label': '2:00 PM',
            'value': '2:00 PM',
        },
        {
            'label': '3:00 PM',
            'value': '3:00 PM',
        },
        {
            'label': '4:00 PM',
            'value': '4:00 PM',
        },
        {
            'label': '5:00 PM',
            'value': '5:00 PM',
        },
        {
            'label': '6:00 PM',
            'value': '6:00 PM',
        },
        {
            'label': '7:00 PM',
            'value': '7:00 PM',
        },
        {
            'label': '8:00 PM',
            'value': '8:00 PM',
        },
        {
            'label': '9:00 PM',
            'value': '9:00 PM',
        },
        {
            'label': '10:00 PM',
            'value': '10:00 PM',
        },
        {
            'label': '11:00 PM',
            'value': '11:00 PM',
        },


    ]

    return (
        <div>
            <Steper current={5} />
            {
                fromloading ?

                    <Form
                        onFinish={onFinish}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}

                        initialValues={{
                            "night": {
                                "arrive_time": inputsValue.night ? inputsValue.night.arrive_time : '',
                                "leave_time": inputsValue.night ? inputsValue.night.leave_time : '',
                                "thu": inputsValue.night ? inputsValue.night.thu : '',
                                "fri": inputsValue.night ? inputsValue.night.fri : '',
                                "sat": inputsValue.night ? inputsValue.night.sat : '',
                                "rest_of_week": inputsValue.night ? inputsValue.night.rest_of_week : '',
                            },
                            "day_time": {
                                "arrive_time": inputsValue.day_time ? inputsValue.day_time.arrive_time : '',
                                "leave_time": inputsValue.day_time ? inputsValue.day_time.leave_time : '',
                                "thu": inputsValue.day_time ? inputsValue.day_time.thu : '',
                                "fri": inputsValue.day_time ? inputsValue.day_time.fri : '',
                                "sat": inputsValue.day_time ? inputsValue.day_time.sat : '',
                                "rest_of_week": inputsValue.day_time ? inputsValue.day_time.rest_of_week : '',
                            },
                            "night_time": {
                                "arrive_time": inputsValue.night_time ? inputsValue.night_time.arrive_time : '',
                                "leave_time": inputsValue.night_time ? inputsValue.night_time.leave_time : '',
                                "thu": inputsValue.night_time ? inputsValue.night_time.thu : '',
                                "fri": inputsValue.night_time ? inputsValue.night_time.fri : '',
                                "sat": inputsValue.night_time ? inputsValue.night_time.sat : '',
                                "rest_of_week": inputsValue.night_time ? inputsValue.night_time.rest_of_week : '',
                            }
                        }}

                    >

                        <div className='row'>
                            <div className='col-lg-8'>
                                <Divider orientation="left" className='text-main'> <span className='text-main'>  حدد نوعية مدة الإقامة لحجوزاتك على المنصة </span> </Divider>
                                <Paragraph>
                                    يمكنك تحديد نوعية مدة الإقامة علي المنصة كآن تكون باللية أو بالفترة (فترة صباحية أو فترة مسائية) أو بجميع الأنواع حسب رغبتك في عرض الوحدة

                                    لــيـلـــة أو كــثر: وهو حجز ليلة كاملة او أكثر و التي قد تكون مدتها 22 ساعة أو أقل أو أكثر (مثال: من الساعة 03:00 مســاء إلى الساعة 01:00 مســـاء)

                                    فترة صباحية: وهو حجز نصف يوم نهارا والتي قد تكون مدته 10ساعات أو أقل أو أكثر (مثال: من الساعة 06:00 صباحا إلى الساعة 04:00 مساء)

                                    فترة مسائية: وهو حجز نصف يوم ليلآ و التي قد تكون مدته 10 ساعات أو أقل أو أكتر (مثال: من الساعة 06:00 مســاء إلى الساعة 04:00 صباحا)
                                </Paragraph>
                                <div className='d-flex justify-content-around'>
                                    <Checkbox onChange={onChangenight} value={night} checked={night}> ليلة أو أكثر </Checkbox>
                                    <Checkbox onChange={onChangeday_time} checked={day_time}> نصف يوم (الفترة الصباحية)</Checkbox>
                                    <Checkbox onChange={onChangenight_time} checked={night_time}> نصف يوم (الفترة المسائية) </Checkbox>
                                    {/* <Form.List name="type">
                                        {() => (
                                            <>
                                                <Form.Item
                                                    name='night'
                                                >

                                                </Form.Item>
                                                <Form.Item
                                                    name='day_time'
                                                >
                                                </Form.Item>
                                                <Form.Item
                                                    name='night_time'
                                                >
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List> */}
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <Divider orientation="left" className='text-main'> <span className='text-main'> ادخل سعر الحجز وفقا لمدة الإقامة </span> </Divider>

                                {
                                    night ?

                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <p>
                                                    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.76245 18.9589L0.434219 10.6252C0.194019 10.3848 0.059082 10.0587 0.059082 9.71877C0.059082 9.3788 0.194019 9.05275 0.434219 8.81232L2.24593 6.99685C2.4862 6.7565 2.81204 6.62147 3.15178 6.62147C3.49153 6.62147 3.81737 6.7565 4.05764 6.99685L9.6683 12.6112L21.6853 0.586321C21.9256 0.345963 22.2514 0.210937 22.5911 0.210938C22.9309 0.210937 23.2567 0.345963 23.497 0.586321L25.3087 2.39922C25.5489 2.63965 25.6838 2.9657 25.6838 3.30567C25.6838 3.64564 25.5489 3.97169 25.3087 4.21212L10.5742 18.9589C10.3339 19.1993 10.008 19.3343 9.6683 19.3343C9.32855 19.3343 9.00272 19.1993 8.76245 18.9589Z" fill="#669933" />
                                                    </svg>
                                                    <span className='fw-bolder ms-4'>ليلة أو أكثر</span>
                                                </p>
                                            </div>
                                            <div className='col-lg-8'>

                                                <Form.List name="night">
                                                    {() => (
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='thu'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder='الخميس' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='fri'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder=' الجمعة  ' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='sat'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder=' السبت  ' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='rest_of_week'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder='وسط الاسبوع' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    )}

                                                </Form.List>
                                            </div>
                                        </div>

                                        : null
                                }
                                {
                                    day_time ?

                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <p>
                                                    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.76245 18.9589L0.434219 10.6252C0.194019 10.3848 0.059082 10.0587 0.059082 9.71877C0.059082 9.3788 0.194019 9.05275 0.434219 8.81232L2.24593 6.99685C2.4862 6.7565 2.81204 6.62147 3.15178 6.62147C3.49153 6.62147 3.81737 6.7565 4.05764 6.99685L9.6683 12.6112L21.6853 0.586321C21.9256 0.345963 22.2514 0.210937 22.5911 0.210938C22.9309 0.210937 23.2567 0.345963 23.497 0.586321L25.3087 2.39922C25.5489 2.63965 25.6838 2.9657 25.6838 3.30567C25.6838 3.64564 25.5489 3.97169 25.3087 4.21212L10.5742 18.9589C10.3339 19.1993 10.008 19.3343 9.6683 19.3343C9.32855 19.3343 9.00272 19.1993 8.76245 18.9589Z" fill="#669933" />
                                                    </svg>
                                                    <span className='fw-bolder ms-4'>  نصف يوم (الفترة الصباحية)  </span>
                                                </p>
                                            </div>
                                            <div className='col-lg-8'>

                                                <Form.List name="day_time">
                                                    {() => (
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='thu'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder='الخميس' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='fri'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder=' الجمعة  ' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='sat'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder=' السبت  ' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='rest_of_week'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder='وسط الاسبوع' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    )}

                                                </Form.List>
                                            </div>
                                        </div>
                                        : null
                                }

                                {
                                    night_time ?

                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <p>
                                                    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.76245 18.9589L0.434219 10.6252C0.194019 10.3848 0.059082 10.0587 0.059082 9.71877C0.059082 9.3788 0.194019 9.05275 0.434219 8.81232L2.24593 6.99685C2.4862 6.7565 2.81204 6.62147 3.15178 6.62147C3.49153 6.62147 3.81737 6.7565 4.05764 6.99685L9.6683 12.6112L21.6853 0.586321C21.9256 0.345963 22.2514 0.210937 22.5911 0.210938C22.9309 0.210937 23.2567 0.345963 23.497 0.586321L25.3087 2.39922C25.5489 2.63965 25.6838 2.9657 25.6838 3.30567C25.6838 3.64564 25.5489 3.97169 25.3087 4.21212L10.5742 18.9589C10.3339 19.1993 10.008 19.3343 9.6683 19.3343C9.32855 19.3343 9.00272 19.1993 8.76245 18.9589Z" fill="#669933" />
                                                    </svg>
                                                    <span className='fw-bolder ms-4'> نصف يوم (الفترة المسائية)  </span>
                                                </p>
                                            </div>
                                            <div className='col-lg-8'>

                                                <Form.List name="night_time">
                                                    {() => (
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='thu'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder='الخميس' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='fri'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder=' الجمعة  ' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='sat'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder=' السبت  ' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-6 col-lg-3'>

                                                                <Form.Item
                                                                    name='rest_of_week'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder='وسط الاسبوع' type="number" className='w-100' />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    )}

                                                </Form.List>
                                            </div>
                                        </div>

                                        : null
                                }
                            </div>

                            <div className='col-lg-12'>
                                <Divider orientation="left" className='text-main'> <span className='text-main'> أدخل أوقات الوصول والمغادرة </span> </Divider>

                                <div className='row'>
                                    <div className='col-lg-4'>

                                    </div>
                                    <div className='col-lg-8'>


                                        <div className='row'>
                                            <div className='col-lg-6'>

                                                <Paragraph>  وقت الوصول </Paragraph>
                                            </div>
                                            <div className='col-lg-6'>

                                               <Paragraph>
                                                وقت المغادرة
                                               </Paragraph>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                                {
                                    night ?
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <p>
                                                    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.76245 18.9589L0.434219 10.6252C0.194019 10.3848 0.059082 10.0587 0.059082 9.71877C0.059082 9.3788 0.194019 9.05275 0.434219 8.81232L2.24593 6.99685C2.4862 6.7565 2.81204 6.62147 3.15178 6.62147C3.49153 6.62147 3.81737 6.7565 4.05764 6.99685L9.6683 12.6112L21.6853 0.586321C21.9256 0.345963 22.2514 0.210937 22.5911 0.210938C22.9309 0.210937 23.2567 0.345963 23.497 0.586321L25.3087 2.39922C25.5489 2.63965 25.6838 2.9657 25.6838 3.30567C25.6838 3.64564 25.5489 3.97169 25.3087 4.21212L10.5742 18.9589C10.3339 19.1993 10.008 19.3343 9.6683 19.3343C9.32855 19.3343 9.00272 19.1993 8.76245 18.9589Z" fill="#669933" />
                                                    </svg>
                                                    <span className='fw-bolder ms-4'>ليلة أو أكثر</span>
                                                </p>
                                            </div>
                                            <div className='col-lg-8'>

                                                <Form.List name="night">
                                                    {() => (
                                                        <div className='row'>
                                                            <div className='col-lg-6'>

                                                                <Form.Item
                                                                    name='arrive_time'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >

                                                                    <Select
                                                                        className='w-100'
                                                                        showSearch
                                                                        placeholder="وقت الوصول"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        options={optionsAllTimes}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'هذا الحقل مطلوب!',
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-lg-6'>

                                                                <Form.Item
                                                                    name='leave_time'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >

                                                                    <Select
                                                                        className='w-100'
                                                                        showSearch
                                                                        placeholder="وقت المغادرة"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        options={optionsAllTimes}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'هذا الحقل مطلوب!',
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Form.Item>
                                                            </div>


                                                        </div>
                                                    )}

                                                </Form.List>
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    day_time ?
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <p>
                                                    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.76245 18.9589L0.434219 10.6252C0.194019 10.3848 0.059082 10.0587 0.059082 9.71877C0.059082 9.3788 0.194019 9.05275 0.434219 8.81232L2.24593 6.99685C2.4862 6.7565 2.81204 6.62147 3.15178 6.62147C3.49153 6.62147 3.81737 6.7565 4.05764 6.99685L9.6683 12.6112L21.6853 0.586321C21.9256 0.345963 22.2514 0.210937 22.5911 0.210938C22.9309 0.210937 23.2567 0.345963 23.497 0.586321L25.3087 2.39922C25.5489 2.63965 25.6838 2.9657 25.6838 3.30567C25.6838 3.64564 25.5489 3.97169 25.3087 4.21212L10.5742 18.9589C10.3339 19.1993 10.008 19.3343 9.6683 19.3343C9.32855 19.3343 9.00272 19.1993 8.76245 18.9589Z" fill="#669933" />
                                                    </svg>
                                                    <span className='fw-bolder ms-4'> نصف يوم (الفترة الصباحية)  </span>
                                                </p>
                                            </div>
                                            <div className='col-lg-8'>

                                                <Form.List name="day_time">
                                                    {() => (
                                                        <div className='row'>
                                                            <div className='col-lg-6'>

                                                                <Form.Item
                                                                    name='arrive_time'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >

                                                                    <Select
                                                                        className='w-100'
                                                                        showSearch
                                                                        placeholder="وقت الوصول"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        options={optionsAllTimes}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'هذا الحقل مطلوب!',
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-lg-6'>

                                                                <Form.Item
                                                                    name='leave_time'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                >

                                                                    <Select
                                                                        className='w-100'
                                                                        showSearch
                                                                        placeholder="وقت المغادرة"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        options={optionsAllTimes}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'هذا الحقل مطلوب!',
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Form.Item>
                                                            </div>


                                                        </div>
                                                    )}

                                                </Form.List>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                {night_time ?
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <p>
                                                <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.76245 18.9589L0.434219 10.6252C0.194019 10.3848 0.059082 10.0587 0.059082 9.71877C0.059082 9.3788 0.194019 9.05275 0.434219 8.81232L2.24593 6.99685C2.4862 6.7565 2.81204 6.62147 3.15178 6.62147C3.49153 6.62147 3.81737 6.7565 4.05764 6.99685L9.6683 12.6112L21.6853 0.586321C21.9256 0.345963 22.2514 0.210937 22.5911 0.210938C22.9309 0.210937 23.2567 0.345963 23.497 0.586321L25.3087 2.39922C25.5489 2.63965 25.6838 2.9657 25.6838 3.30567C25.6838 3.64564 25.5489 3.97169 25.3087 4.21212L10.5742 18.9589C10.3339 19.1993 10.008 19.3343 9.6683 19.3343C9.32855 19.3343 9.00272 19.1993 8.76245 18.9589Z" fill="#669933" />
                                                </svg>
                                                <span className='fw-bolder ms-4'>   نصف يوم (الفترة المسائية)  </span>
                                            </p>
                                        </div>
                                        <div className='col-lg-8'>

                                            <Form.List name="night_time">
                                                {() => (
                                                    <div className='row'>
                                                        <div className='col-lg-6'>

                                                            <Form.Item
                                                                name='arrive_time'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'هذا الحقل مطلوب!',
                                                                    },
                                                                ]}
                                                            >

                                                                <Select
                                                                    className='w-100'
                                                                    showSearch
                                                                    placeholder="وقت الوصول"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    options={optionsAllTimes}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className='col-lg-6'>

                                                            <Form.Item
                                                                name='leave_time'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'هذا الحقل مطلوب!',
                                                                    },
                                                                ]}
                                                            >

                                                                <Select
                                                                    className='w-100'
                                                                    showSearch
                                                                    placeholder="وقت المغادرة"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    options={optionsAllTimes}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل مطلوب!',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                        </div>


                                                    </div>
                                                )}

                                            </Form.List>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                            </div>

                            <div className='col-lg-4 offset-lg-1'>


                                <Button
                                    loading={loading}
                                    size='large' block type="primary" htmlType="submit">
                                    {loading ? 'جاري ...' : 'التالي'}
                                </Button>
                            </div>
                            <div className='col-lg-4 offset-lg-1'>
                                <Button size='large' block>
                                    <Link className='d-block text-decoration-none' to='/add-property/step-4'> رجوع </Link>
                                </Button>

                            </div>
                        </div>

                    </Form>

                    :

                    <div className='text-center'>
                        <Spin size="large" />
                    </div>
            }


        </div>
    );
}

export default Step5;
