import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Tag } from "antd";
import { del, get } from "../../api/axios";
import { Pagination, message } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, FileAddOutlined } from "@ant-design/icons";

const Payouts = () => {
  // render: (role) => (role == null ? "" : role.name),
  const columns = [
    {
      title: " رقم الحجز",
      dataIndex: "id",
    },
    {
      title: " حالة الحجز",
      dataIndex: "reservation",
      render: ({ status }) => status,
    },
    {
      title: " بيانات الحجز ",
      dataIndex: "reservation",
      render: ({ date_of_creation, time_of_creation, cancellation_policy }) => (
        <span>
          تاريخ انشاء الحجز:
          <br />
          {date_of_creation}
          <br />
          وقت الحجز:
          <br />
          {time_of_creation}
          <br />
          سياسة الالغاء:
          <br />
          {cancellation_policy}
          <br />
        </span>
      ),
    },
    {
      title: " بيانات الوصول والمغادرة ",
      dataIndex: "reservation",
      render: ({ arrives_leaves }) => (
        <span>
          تاريخ الوصول والمغادرة
          <br />
          {arrives_leaves.date}
          <br />
          وقت الوصول:
          <br />
          {arrives_leaves.arriving_time}
          <br />
          وقت المغادرة:
          <br />
          {arrives_leaves.leaving_time}
          <br />
          نوع الاقامة:
          <br />
          {arrives_leaves.type}
          <br />
        </span>
      ),
    },
    {
      title: " بيانات السعر والدفع ",
      dataIndex: "reservation",
      render: ({ prices }) => (
        <span>
          {prices.subTotal}
          <br />
          الخصم (عرض)
          <br />
          {prices.offers_amount} ريال
          <br />
          الخصم (كود خصم)
          <br />
          {prices.coupon_amount} ريال
          <br />
          {/* مبلغ الحجز:
                    <br />
                    {prices.subTotal}
                    <br /> */}
          رسوم الخدمة:
          <br />
          {prices.commission_amount} ريال
          <br />
          المجموع:
          <br />
          {prices.totalPrice} ريال
          <br />
        </span>
      ),
    },
    {
      title: (
        <>
          تاريخ انشاء الحوالة
          <br />
          رقم الحوالة
          <br />
          مبلغ الحوالة
          <br />
        </>
      ),
      render: ({ payout_date, id, amount }) => (
        <span>
          {payout_date}
          <br />
          {id}
          <br />
          {amount}
        </span>
      ),
    },
    {
      title: " بيانات الوحدة ",
      dataIndex: "reservation",
      render: ({ property }) => (
        <span>
          الوحدة:
          {property.name}
          <br />
          المدينة:
          {property.city}
          <br />
          الحي:
          {property.district}
          <br />
          كود الوحدة:
          <br />
          {property.code}
          <br />
        </span>
      ),
    },
    {
      title: "   بيانات المضيف  ",
      dataIndex: "reservation",
      render: ({ owner }) => (
        <span>
          الاسم:
          {owner.name}
          <br />
          الجوال:
          {owner.phone}
          <br />
          البريد الإلكتروني:
          {owner.email}
        </span>
      ),
    },
    // {
    //   title: "تعديل الحوالة",
    //   render: ({ id }) => (
    //     <button className="btn btn-outline-warning btn-sm p-0">
    //       <Link
    //         className="color-inherit text-decoration-none py-1 px-2 d-block"
    //         to={`/payouts/update/${id}`}
    //       >
    //         <EditOutlined />
    //         تعديل
    //       </Link>
    //     </button>
    //   ),
    // },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("payouts").then(res => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`payouts?page=${pageNumber}`).then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                //     x: 1350,
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payouts;
