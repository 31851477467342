import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Spin,
  Form,
} from "antd";
import { get, putFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const { Title } = Typography;

const EditUnitRegistration = () => {
  const navigate = useNavigate();
  let { Id } = useParams();
  const [optionsregion_id, setOptionsregion_id] = useState([]);
  const [optionsregion_idData, setOptionsregion_idData] = useState([]);

  const [optionscity_id, setOptionscity_id] = useState([]);
  const [optionscity_idData, setOptionscity_idData] = useState([]);

  const [optionsdistrict_id, setOptionsdistrict_id] = useState([]);
  const [optionsdistrict_idData, setOptionsdistrict_idData] = useState([]);

  const [dataCity, setDataCity] = useState([]);
  const [optionCity, setOptionCity] = useState([]);
  useEffect(() => {
    setOptionCity(
      dataCity.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataCity]);
  useEffect(() => {
    setOptionscity_idData(
      optionscity_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionscity_id]);

  const [dataPay_type, setDataPay_type] = useState([]);
  const [optionPay_type, setOptionPay_type] = useState([]);
  useEffect(() => {
    setOptionPay_type(
      dataPay_type.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataPay_type]);

  useEffect(() => {
    get("get-all-regions").then(res => {
      // setFromLoading(false);
      setOptionsregion_id(res.data);
    });
    get("get-all-cities").then(res => {
      setDataCity(res.data);
    });
    get("get-all-property-types").then(res => {
      setDataPay_type(res.data);
    });
  }, []);

  useEffect(() => {
    setOptionsregion_idData(
      optionsregion_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionsregion_id]);
  useEffect(() => {
    setOptionsdistrict_idData(
      optionsdistrict_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionsdistrict_id]);

  const [fromloading, setFromLoading] = useState(false);
  const [form] = Form.useForm();

  const [inputsValue, setinputsValue] = useState({ properties: [] });

  useEffect(() => {
    get(`unit-registrations/${Id}`).then(res => {
      setinputsValue(res.data);
      setFromLoading(true);

      get(`get-cities-by-region/${res.data.region?.id}`).then(response => {
        setOptionscity_id(response.data);
      });
      get(`get-districts-by-city/${res.data.city?.id}`).then(response => {
        setOptionsdistrict_id(response.data);
      });
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const onChangeregion_id = value => {
    form.setFieldsValue({
      district_id: '',
      city_id: '',
    });
    get(`get-cities-by-region/${value}`).then(response => {
      setOptionscity_id(response.data);
    });
  };
  const onChangecity_id = value => {
    form.setFieldsValue({
      district_id: '',
    });

    get(`get-districts-by-city/${value}`).then(response => {
      setOptionsdistrict_id(response.data);
    });
  };
  const onChangedistrict_id = value => {
    console.log("value", value);
    setinputsValue({ ...inputsValue, district_id: value });
  };

  const onFinish = values => {
    setLoading(true);

    putFromData(`unit-registrations/${Id}`, {
      ...values,
    })
      .then(res => {
        message.success("تم تعديل بنجاح");
        setLoading(false);
        navigate("/unit-registrations");
      })
      .catch(err => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        تعديل الوحدة{" "}
      </Title>
      {fromloading ? (
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            property_type_id: inputsValue.property_type.id,
            region_id: inputsValue.region?.id,
            city_id: inputsValue.city?.id,
            district_id: inputsValue.district?.id,
            property_name: inputsValue.property_name,
            owner_name: inputsValue.owner_name,
            marketer_number: inputsValue.marketer_number,
            email: inputsValue.email,
            first_phone_number: inputsValue.first_phone_number,
            second_phone_number: inputsValue.second_phone_number,
            whatsapp_number: inputsValue.whatsapp_number,
            property_level: inputsValue.property_level,
            note: inputsValue.note,

            action:
              inputsValue.action == "تم تعبئة النموذج"
                ? 2
                : "تم إرسال طلب إضافة عقار"
                  ? 3
                  : "رفض إضافة عقاره معنا"
                    ? 4
                    : "1",
            youtube_url: inputsValue.youtube_url,
            instagram_url: inputsValue.instagram_url,
            twitter_url: inputsValue.twitter_url,
            snapchat_url: inputsValue.snapchat_url,
          }}
        >
          <div className="row">
            <div className="col-lg-4">
              <Form.Item
                label="اسم الوحدة"
                name="property_name"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="رقم المالك"
                name="owner_name"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="رقم المسوق"
                name="marketer_number"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="البريد الإلكتروني"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="رقم الجوال 1"
                name="first_phone_number"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="رقم الجوال 2"
                name="second_phone_number"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="واتساب  "
                name="whatsapp_number"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="انستجرام  "
                name="instagram_url"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="تويتر  "
                name="twitter_url"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="سناب شات  "
                name="snapchat_url"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="يوتيوب   "
                name="youtube_url"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item label="ملحوظة   " name="note">
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="الاجراء"
                name="action"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الاجراء"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 1,
                      label: "مسجل",
                    },
                    {
                      value: 2,
                      label: "نموذج معبأ",
                    },
                    {
                      value: 3,
                      label: "تم إرسال طلب إضافة عقار",
                    },
                    {
                      value: 4,
                      label: "تم رفض إضافة خاصية",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="مستوي الوحدة"
                name="property_level"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد مستوي الوحدة"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 0,
                      label: "0",
                    },

                    {
                      value: 1,
                      label: "1",
                    },
                    {
                      value: 2,
                      label: "2",
                    },
                    {
                      value: 3,
                      label: "3",
                    },
                    {
                      value: 4,
                      label: "4",
                    },
                    {
                      value: 5,
                      label: "5",
                    },
                    {
                      value: 6,
                      label: "6",
                    },
                    {
                      value: 7,
                      label: "7",
                    },
                    {
                      value: 8,
                      label: "8",
                    },
                    {
                      value: 9,
                      label: "9",
                    },
                    {
                      value: 10,
                      label: "10",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="نوع نوع الملكية"
                name="property_type_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد نوع نوع الملكية"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionPay_type}
                />
              </Form.Item>
            </div>


            <div className="col-lg-4">
              <Form.Item
                label="المنطة"
                name="region_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المنطة"
                  optionFilterProp="children"
                  onChange={onChangeregion_id}
                  options={optionsregion_idData}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="المدينة"
                name="city_id"
                value={inputsValue.city_id}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المدينة"
                  optionFilterProp="children"
                  onChange={onChangecity_id}
                  value={inputsValue.city_id}
                  options={optionscity_idData}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="الحى"
                name="district_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الحى"
                  optionFilterProp="children"
                  onChange={onChangedistrict_id}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionsdistrict_idData}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>

            {/* <div className="col-lg-4">
              <Form.Item
                label="المدينة"
                name="city_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المدينة"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionCity}
                />
              </Form.Item>
            </div>

            <div className="col-lg-4">
              <Form.Item
                label="الحي"
                name="district"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div> */}

            <div className="col-lg-6 offset-lg-3">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditUnitRegistration;
