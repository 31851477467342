import React, { useState } from "react";
import { Input, Typography, Button, message, Form, Upload } from "antd";
import { baseUrl, post, tokenAdmin } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;

const AddHomeSlider = () => {
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState({
        "order": 0,
        "title": null,
        "description": null,
    });

    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
    };

    const [loading, setLoading] = useState(false);
    const handleUpload = ({ fileList: newFileList }) => {
        setinputsValue({
            ...inputsValue,
            url: newFileList[0].response ? newFileList[0].response.url : "",
        });
    };

    const onFinish = (values) => {
        setLoading(true);

        post("home-sliders", inputsValue)
            .then((res) => {
                message.success("تم الإضافة بنجاح");
                setLoading(false);
                navigate("/home-sliders");
            })
            .catch((err) => {
                message.error(err.response.data.message);
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-4">
                إضافة صورة جديدة
            </Title>
            <Form
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    <div className="col-lg-6">
                        <Form.Item
                            label="العنوان"
                            name="title"
                            onChange={handleChange}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="الترتيب"
                            name="order"
                            onChange={handleChange}
                        >
                            <Input type='number' />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="الوصف"
                            name="description"
                            onChange={handleChange}
                        >
                            <TextArea></TextArea>
                        </Form.Item>
                    </div>

                    <div className="col-lg-6">
                        <Form.Item
                            label="الصورة"
                            name="url"
                            rules={[
                                {
                                    required: true,
                                    message: "هذا الحقل مطلوب!",
                                },
                            ]}
                        >
                            <Upload
                                action={`${baseUrl}upload-slide-image/store`}
                                headers={{ Authorization: `Bearer ${tokenAdmin}` }}
                                listType="picture"
                                maxCount={1}
                                onChange={handleUpload}
                            >
                                <Button icon={<UploadOutlined />}> اضغظ لتحميل الصورة </Button>
                            </Upload>
                        </Form.Item>
                    </div>

                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "جاري الإضافة..." : "إضافة"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddHomeSlider;
