import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Switch, Table, Tag } from "antd";
import { get, del, postFromData, newPostFromData, post } from "../../api/axios";
import { Link } from "react-router-dom";
import { Pagination, message } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";

const Coupons = () => {
  const columns = [
    {
      title: "الرقم ",
      dataIndex: "id",
    },
    {
      title: "رقم الطلب ",
      dataIndex: "number_of_request",
    },
    {
      title: "تاريخ الطلب ",
      dataIndex: "date_of_request",
    },
    {
      title: " كود الخصم ",
      dataIndex: "code",
    },
    {
      title: " كود الوحدة",
      dataIndex: "property_code",
    },
    {
      title: " تاريخ تطبيف كود الخصم ",
      children: [
        {
          title: "   تاريخ البداية",
          dataIndex: "start_date",
        },
        {
          title: "         تاريخ النهاية    ",
          dataIndex: "end_date",
        },
      ],
    },
    {
      title: "   	نسبة الخصم ",
      children: [
        {
          title: " ليلة",
          render: ({ night }) => <>% {night} </>,
          // dataIndex: "night",
        },
        {
          title: "فترة صباحية ",
          render: ({ day_time }) => <>% {day_time} </>,
          // dataIndex: "day_time",
        },
        {
          title: "  فترة مسائية ",
          render: ({ night_time }) => <>% {night_time} </>,
          // dataIndex: "night_time",
        },
      ],
    },
    {
      title: "  حالة الطلب ",
      render: ({ status }) => (
        <>
          <Tag color={`${status == "منتهي" ? "error" : "success"}`}>
            {status}
          </Tag>
        </>
      ),
    },
  ];

  // if (checkPermission("delete-coupon")) {
  //   columns.push({
  //     title: "حذف",
  //     render: ({ id, status }) => (
  //       <Popconfirm
  //         disabled={status == "منتهي" ? true : false}
  //         title="انهاء العنصر"
  //         description="هل أنت متأكد من انهاء هذه العنصر؟"
  //         onConfirm={() => confirm(id)}
  //         onCancel={cancel}
  //         okText="نعم"
  //         cancelText="لا"
  //       >
  //         <Button danger disabled={status == "منتهي" ? true : false}>
  //           حذف
  //         </Button>
  //       </Popconfirm>
  //     ),
  //   });
  // }
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("coupons?page=1").then(res => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const handleChangeStatus = (id, status) => {
    post(`change-coupon-status`, { coupon_id: id, status: status ? 1 : 2 });
    message.success("تم تغير الحالة");

    const newData = [];
    data.map(item => {
      if (item.id == id) {
        newData.push({ ...item, status: !item["status"] });
      } else {
        newData.push(item);
      }
    });
    setdata(newData);
    // console.log('newData',newData);
  };

  // const handleDelet = (id) => {
  //     postFromData('change-coupon-status', {

  //     }).then(res => {

  //     }).catch(err => {

  //     })

  //     const newData = []
  //     data.map(item => {
  //         if(item.id == id) {
  //             newData.push({...item, status: 'محذوف' })

  //         } else {
  //             newData.push(item)
  //         }

  //     })
  //     setdata(newData)
  // }
  const handleDelet = id => {
    postFromData(`change-coupon-status`, { coupon_id: id, status: 2 });

    const newData = [];
    data.map(item => {
      if (item.id == id) {
        newData.push({ ...item, status: "منتهي" });
      } else {
        newData.push(item);
      }
    });
    setdata(newData);
  };

  const confirm = id => {
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = e => {
    console.log(e);
    message.error("تم الغاء الامر");
  };

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`coupons?page=${pageNumber}`).then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                y: "65vh",
                x: 650,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupons;
