import React, { useEffect, useState } from 'react'
import { Input, Typography, Button, message, Select, Form, Upload } from 'antd';
import { axiosApi1, get, newPostFromData, post, postFromData, tokenAdmin } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Title } = Typography;

const AddCity = () => {
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState(
        {
            "name_ar": '',
            "name_en": "",
            "region_id": "",
        }
    );
    const [dataRegions, setDataRegions] = useState([]);
    const [option, setOption] = useState([]);

    useEffect(() => {
        setOption(dataRegions.map(d => ({
            "value": d.id,
            "label": d.name_ar,
        })))

    }, [dataRegions]);

    useEffect(() => {
        get('get-all-regions').then(res => {
            setDataRegions(res.data)
        })
    }, []);

    const onChange = (value) => {
        console.log(`selected ${value}`);
        setinputsValue({ ...inputsValue, region_id: value })
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };




    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })

    }

    const [loading, setLoading] = useState(false);

    const handleUpload = e => {
        console.log('djklf', e.file);
        setinputsValue({ ...inputsValue, image: e.file.originFileObj })
    }


    const onFinish = (values) => {
        setLoading(true);

        // postFromData('cities', inputsValue).then(res => {
        //     message.success('تم الإضافة بنجاح');
        //     setLoading(false);
        //     navigate('/cities')
        // }).catch(err => {
        //     message.error(err.response.data.message);
        //     setLoading(false);
        // })

        let formData = new FormData();
        // formData.append('image', inputsValue.image);
        // formData.append('name_ar', inputsValue.name_ar);
        // formData.append('name_en', inputsValue.name_en);
        // formData.append('region_id', inputsValue.region_id);

        for (let item in inputsValue) {
            formData.append(item, inputsValue[item])
        }
        console.log('inputsValue', inputsValue);
        axiosApi1.post(
            'cities',
            formData,
        )
            .then(res => {
                message.success('تم الإضافة بنجاح');
                setLoading(false);
                navigate('/cities')
            })
            .catch(err => {
                message.error(err.response.data.message);
                setLoading(false);
            })

    };



    return (
        <div>
            <Title level={2} className='text-main mb-4'> إضافة مدينة جديدة </Title>
            <Form
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}

                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className='row'>

                    <div className='col-lg-6'>

                        <Form.Item
                            label='الاسم بالعربي'
                            name='name_ar'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className='col-lg-6'>

                        <Form.Item
                            label='الاسم بالانجليزي'
                            name='name_en'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <div className='col-lg-6'>
                        <Form.Item
                            label='المنطقة'
                            name='region_id'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Select
                                className='w-100'
                                showSearch
                                placeholder="حدد المنطقة"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={option}
                            />

                        </Form.Item>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Item
                            label='الصورة'
                            name='image'
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Upload
                                action="https://test.book-makan.com/api/admin/upload/store"
                                headers={{ "Authorization": `Bearer ${tokenAdmin}` }}
                                listType="picture"
                                maxCount={1}
                                onChange={handleUpload}
                            >
                                <Button icon={<UploadOutlined />}> اضغظ لتحميل الصورة </Button>
                            </Upload>
                        </Form.Item>
                    </div>

                    <div className='col-lg-4 offset-lg-4'>

                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size='large' block type="primary" htmlType="submit">
                                {loading ? 'جاري الإضافة...' : 'إضافة'}
                            </Button>
                        </Form.Item>
                    </div>
                </div>



            </Form>
        </div>
    )
}

export default AddCity


// import React, { Component } from 'react';
// import axios from "axios";
// import { newPostFromData, post, postFromData } from '../../api/axios';

// class FileUpload extends Component {

//     // API Endpoints
//     custom_file_upload_url = `https://test.book-makan.com/api/admin/cities`;


//     constructor(props) {
//         super(props);
//         this.state = {
//             image_file: null,
//             image_preview: '',
//         }
//     }

//     // Image Preview Handler
//     handleImagePreview = (e) => {
//         // let image_as_base64 = URL.createObjectURL(e.target.files[0])
//         let image_as_files = e.target.files[0];

//         this.setState({
//             // image_preview: image_as_base64,
//             image_file: image_as_files,
//         })
//         console.log('e.target.files[0]',e.target.files[0]);

//     }

//     // Image/File Submit Handler
//     handleSubmitFile = () => {
//         if (this.state.image_file !== null){

//             let formData = new FormData();
//             formData.append('image', this.state.image_file);
//             formData.append('name_ar', '111e');
//             formData.append('name_en', '11ee');
//             formData.append('region_id', 6);
//             // the image field name should be similar to your api endpoint field name
//             // in my case here the field name is customFile

//             // post('cities', formData)

//             axios.post(
//                 this.custom_file_upload_url,
//                 formData,
//                 {
//                     headers: {
//                         "Authorization": "Bearer 74|niDKkrfAxBi5NRuLrD8dmxeTOSULToIJaHVnTztA",
//                         // "Content-type": "multipart/form-data",
//                     },
//                 }
//             )
//             .then(res => {
//                 console.log(`Success` + res.data);
//             })
//             .catch(err => {
//                 console.log(err);
//             })
//         }
//     }


//     // render from here
//     render() {
//         return (
//             <div>
//                 {/* image preview */}
//                 <img src={this.state.image_preview} alt="image_preview"/>

//                 {/* image input field */}
//                 <input
//                     type="file"
//                     onChange={this.handleImagePreview}
//                 />
//                 <label>Upload file</label>
//                 <input type="submit" onClick={this.handleSubmitFile} value="Submit"/>
//             </div>
//         );
//     }
// }

// export default FileUpload;