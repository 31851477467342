import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Tag } from "antd";
import { del, get } from "../../api/axios";
import { Pagination, message } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";

const OwnersRates = () => {
  // ant-table-content
  const columns = [
    {
      title: " الرقم   ",
      dataIndex: "id",
    },
    {
      title: " رقم الحجز  ",
      dataIndex: "reservation_number",
    },

    {
      title: " كود الوحدة  ",
      dataIndex: "property_code",
    },
    {
      title: " رقم المستخدم (المضيف)  ",
      dataIndex: "owner_id",
    },
    {
      title: "  تاريخ التقييم ",
      dataIndex: "reservation_date",
      width: "120px",
    },
    {
      title: "                  تعليق المضيف              ",
      dataIndex: "owner_comment",
      width: "140px",
    },
    {
      title: " اللباقة  ",
      render: ({ tact_text, tact_number }) => (
        <span>
          {tact_number} <br /> {tact_text}
        </span>
      ),
    },
    {
      title: " النظافة  ",
      render: ({ clean_text, clean_number }) => (
        <span>
          {clean_number} <br /> {clean_text}
        </span>
      ),
    },
    {
      title: " الحرص  ",
      render: ({ care_number, care_text }) => (
        <span>
          {care_number} <br /> {care_text}
        </span>
      ),
    },
    {
      title: " التعليمات  ",
      render: ({ instructions_number, instructions_text }) => (
        <span>
          {instructions_number} <br /> {instructions_text}
        </span>
      ),
    },
    {
      title: " مستوي الرضا  ",
      render: ({ satisfaction_number, satisfaction_text }) => (
        <span>
          {satisfaction_number} <br /> {satisfaction_text}
        </span>
      ),
    },
    {
      title: " التقييم العام  ",
      render: ({ total_rate_number, total_rate_text }) => (
        <span>
          {total_rate_number} <br /> {total_rate_text}
        </span>
      ),
    },

    // @#@#
    {
      title: " رقم المستخدم (الضيف)  ",
      dataIndex: "user_id",
    },

    {
      title: "  حالة التقييم ",
      render: ({ status }) => (
        <>
          <Tag color={`${status == "محذوف" ? "error" : "success"}`}>
            {status}
          </Tag>
        </>
      ),
    },
  ];
  //
  if (checkPermission("delete-owner-rate")) {
    columns.push({
      title: "حذف",
      render: ({ id, status }) => (
        <Popconfirm
          disabled={status == "محذوف" ? true : false}
          title="حذف العنصر"
          description="هل أنت متأكد من حذف هذه العنصر؟"
          onConfirm={() => confirm(id)}
          onCancel={cancel}
          okText="نعم"
          cancelText="لا"
        >
          <Button danger disabled={status == "محذوف" ? true : false}>
            حذف
          </Button>
        </Popconfirm>
      ),
    });
  }
  const handleDelet = id => {
    del(`delete-owner-rate/${id}`);

    const newData = [];
    data.map(item => {
      if (item.id == id) {
        newData.push({ ...item, status: "محذوف" });
      } else {
        newData.push(item);
      }
    });
    setdata(newData);
  };

  const confirm = id => {
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = e => {
    console.log(e);
    message.error("تم الغاء الامر");
  };
  const [dataFirst, setdataFirst] = useState([]);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("get-owners-rates").then(res => {
      setdataFirst(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log("dataFirst", dataFirst);
    const newarr = [];
    dataFirst.map(item => {
      const newObj = {};

      // item
      newObj.id = item.id;
      newObj.property_code = item.property_code;
      newObj.owner_comment = item.owner_comment;
      newObj.rate_date = item.rate_date;
      newObj.reservation_number = item.reservation_number;
      newObj.reservation_date = item.date;
      newObj.owner_id = item.owner_id;
      newObj.user_id = item.user_id;

      // item
      newObj.tact_number = item.tact.number;
      newObj.tact_text = item.tact.text;
      // item
      newObj.clean_number = item.clean.number;
      newObj.clean_text = item.clean.text;
      // item
      newObj.care_number = item.care.number;
      newObj.care_text = item.care.text;
      // item
      newObj.instructions_number = item.instructions.number;
      newObj.instructions_text = item.instructions.text;
      // item
      newObj.satisfaction_number = item.satisfaction.number;
      newObj.satisfaction_text = item.satisfaction.text;
      // item
      newObj.total_rate_number = item.total_rate.number;
      newObj.total_rate_text = item.total_rate.text;

      newObj.status = item.status;

      newarr.push(newObj);
    });
    setdata(newarr);
  }, [dataFirst]);

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`get-owners-rates?page=${pageNumber}`).then(res => {
      setdataFirst(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                y: "65vh",
                x: 1750,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnersRates;
