import React, { useEffect, useState } from "react";
import { Table, Button, Switch } from "antd";
import { del, get, post } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";

const Regions = () => {
  const columns = [
    {
      title: "رقم المنطقة",
      dataIndex: "id",
      render: status => <span>{status}#</span>,
    },
    {
      title: "الاسم بالعربي",
      dataIndex: "name_ar",
    },
    {
      title: "الاسم بالانجليزي",
      dataIndex: "name_en",
    },
    {
      title: "الحالة",
      dataIndex: "id",
      render: (id, { status }) => (
        // <
        //     title="تغير الحالة"
        //     description="هل أنت متأكد من تغير الحالة؟"
        //     // onConfirm={() => confirm(id)}
        //     onCancel={cancel}
        //     okText="نعم"
        //     cancelText="لا"
        // >
        <>
          <Switch
            onChange={() => handleChangeStatus(id, status)}
            checked={status}
            style={{
              margin: 16,
            }}
          />
        </>
      ),
    },

    // {
    //     title: 'حذف',
    //     dataIndex: 'id',
    //     render: (id) => (
    //         <Popconfirm
    //             title="حذف العنصر"
    //             description="هل أنت متأكد من حذف هذه العنصر؟"
    //             onConfirm={() => confirm(id)}
    //             onCancel={cancel}
    //             okText="نعم"
    //             cancelText="لا"
    //         >
    //             <Button danger >
    //                 حذف
    //             </Button>
    //         </Popconfirm>
    //     ),
    // },
  ];

  if (checkPermission("update-region")) {
    columns.push({
      title: "تعديل",
      dataIndex: "id",
      render: id => (
        <button className="btn btn-outline-warning btn-sm p-0">
          <Link
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            to={`/regions/edit/${id}`}
          >
            تعديل
          </Link>
        </button>
      ),
    });
  }

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  const handleChangeStatus = (id, status) => {
    post(`change-region-status/${id}`, { status: !status });
    message.success("تم تغير الحالة");

    const newData = [];
    data.map(item => {
      if (item.id == id) {
        newData.push({ ...item, status: !item["status"] });
      } else {
        newData.push(item);
      }
    });
    setdata(newData);
    // console.log('newData',newData);
  };

  useEffect(() => {
    // return () => {
    get("regions?page=1").then(res => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });

    // };
  }, []);

  const handleDelet = id => {
    del(`regions/${id}`);
    let dateFilter = data.filter(item => item.id !== id);
    setdata(dateFilter);
  };

  const confirm = id => {
    console.log(id);
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = e => {
    console.log(e);
    message.error("تم الغاء الامر");
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = newSelectedRowKeys => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const handlePagination = pageNumber => {
    setLoading(true);

    get(`regions?page=${pageNumber}`).then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        {checkPermission("create-region") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
              <Link
                className="text-decoration-none d-block h-100"
                to="/regions/add"
              >
                <Button type="primary" block>
                {" "}
                إضافة{" "}
            </Button>
              </Link>
          </div>
        )}
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 650,
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regions;
