import React, { useEffect, useState } from 'react'
import { Input, Typography, Button, message, Select, Checkbox, Form, } from 'antd';
import { get, postFromData, put } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

const { Title } = Typography;

const EditGuest = () => {
    const navigate = useNavigate();
    let { guestId } = useParams();



    const [inputsValue, setinputsValue] = useState({ "name_ar": '', "name_en": "" });
    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })
    }

   
   // useEffect(() => {
    //     return () => {
    //         get(`guests/${guestId}`).then(res => {
    //             setinputsValue(res.data)
    //         })
    //     }
    // }, []);
    

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        put('guests', inputsValue).then(res => {
            message.success('تم تعديل بنجاح');
            setLoading(false);
            navigate('/guests')
        }).catch(err => {
            message.error('حدث خطآ ما, حاول مرة اخري');
            setLoading(false);
        })

    };


    const [dataCities, setDataCities] = useState([]);
    const [option, setOption] = useState([]);

    useEffect(() => {
        setOption(dataCities.map(d => ({
            "value": d.id,
            "label": d.name_ar,
        })))

    }, [dataCities]);

    useEffect(() => {
        return () => {
            get('cities?page=1').then(res => {
                setDataCities(res.data.data)
            })

        };
    }, []);

    const onChange = (value) => {
        console.log(`selected ${value}`);
        setinputsValue({ ...inputsValue, region_id: value })
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    return (
        <div>
            <Title level={2} className='text-main mb-4'> تعديل الحي  </Title>
            <Form
                onFinish={onFinish}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}

                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className='row'>

                    {/* {
                        inputs.map(item => ( */}

                    <div className='col-lg-4'>

                        <Form.Item
                            label='الاسم بالعربي'
                            name='name_ar'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className='col-lg-4'>

                        <Form.Item
                            label='الاسم بالانجليزي'
                            name='name_en'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className='col-lg-4'>
                    <Form.Item
                            label='المدينة'
                            name='region_id'
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >

                        <Select
                            className='w-100'
                            showSearch
                            placeholder="حدد المدينة"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={option}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        />
                        </Form.Item>
                    </div>

                    <div className='col-lg-4 offset-lg-4'>

                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size='large' block type="primary" htmlType="submit">
                                {loading ? 'جاري التعديل...' : 'تعديل'}
                            </Button>
                        </Form.Item>
                    </div>
                </div>



            </Form>
        </div>
    )
}

export default EditGuest
