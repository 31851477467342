import { MdWavingHand } from "react-icons/md";
import React from 'react';
import { Result } from 'antd';
import { StepForwardOutlined } from "@ant-design/icons";

const Welcome = () => {
    return (
        <>
            <Result
                status='success'
                icon={<MdWavingHand size={100} className="text-main" />}
                title="أهلا بك في لوحة التحكم!"
            />
        </>
    );
}

export default Welcome;
