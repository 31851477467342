import { Popover, Steps } from 'antd';
const customDot = (dot, { status, index }) => (
    <Popover
        content={
            <span>
                step {index} status: {status}
            </span>
        }
    >
        {dot}
    </Popover>
);
const description = 'الوصف';
const Steper = ({ current = 1 }) => (
    <Steps
        current={current - 1}
        // progressDot={customDot}
        className='mb-5'
        items={[
            {
                title: 'المالك  ',
                // description,
            },
            {
                title: 'البيانات   ',
                // description,
            },
            {
                title: 'المواصفات   ',
                // description,
            },
            {
                title: ' الصور  ',
                // description,
            },
            {
                title: 'الاسعار  ',
                // description,
            },
            {
                title: '  المالية ',
                // description,
            },
            {
                title: ' الحجوزات  ',
                // description,
            },
            {
                title: ' الشروط  ',
                // description,
            },
            {
                title: ' الإتفاقة   ',
                // description,
            },
        ]}
    />
);
export default Steper;