import React from 'react';
import { ProtectedRoutesAdmin } from './componts/auth/ProtectedRoutes';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './componts/auth/Login';
import Regions from './componts/regions/regions';
import Cities from './componts/cities/Cities';
import Districts from './componts/districts/Districts';
import Guests from './componts/guests/Guests';
import AddRegion from './componts/regions/AddRegion';
import EditRegion from './componts/regions/EditRegion';
import AddCity from './componts/cities/AddCity';
import EditCity from './componts/cities/EditCity';
import EditDistrict from './componts/districts/EditDistrict';
import AddDistrict from './componts/districts/AddDistrict';
import AddGuest from './componts/guests/AddGuest';
import EditGuest from './componts/guests/EditGuest';
import Agreement from './componts/agreement/Agreement';
import EditEmail from './componts/guests/EditEmail';
import ChangeStatus from './componts/guests/ChangeStatus';
import Owners from './componts/owners/Owners';
import AddOwner from './componts/owners/AddOwner';
import ChangeStatusOwner from './componts/owners/ChangeStatus';
import EditEmailOwner from './componts/owners/EditEmail';
import Properties from './componts/properties/properties';
import AddProperty from './componts/properties/addProperty/AddProperty';
import Step1 from './componts/properties/addProperty/steps/Step1';
import Step2 from './componts/properties/addProperty/steps/step2';
import Step3 from './componts/properties/addProperty/steps/step3';
import Step4 from './componts/properties/addProperty/steps/Step4';
import Step5 from './componts/properties/addProperty/steps/Step5';
import Step6 from './componts/properties/addProperty/steps/Step6';
import Step7 from './componts/properties/addProperty/steps/Step7';
import Step8 from './componts/properties/addProperty/steps/Step8';
import Step9 from './componts/properties/addProperty/steps/Step9';
import Banks from './componts/banks/Banks';
import AddBank from './componts/banks/AddBank';
import EditBank from './componts/banks/EditBank';
import Marketers from './componts/marketers/Marketers';
import AddMarketer from './componts/marketers/AddMarketer';
import EditMarketer from './componts/marketers/EditMarketer';
import Employees from './componts/employees/Employees';
import AddEmployee from './componts/employees/AddEmployee';
import EditEmployee from './componts/employees/EditEmployee';
import Reservations from './componts/reservations/reservations';
import Chats from './componts/chats/chats';
import Showchat from './componts/chats/showchat';
import Statistics from './componts/statistics/statistics';
import Coupons from './componts/coupons/coupons';
import HomeCityTypes from './componts/home-city-types/homeCityTypes';
import AddHomeCityType from './componts/home-city-types/AddHomeCityType';
import EditHomeCityType from './componts/home-city-types/EditHomeCityType';
import UsersRates from './componts/users-rates/users-Rates';
import OwnersRates from './componts/owners-rates/owners-Rates';
import MakanStatistics from './componts/makan-statistics/makan-statistics';
import FestivalPrices from './componts/festival-prices/festival-prices';
import HomeSliders from './componts/home-sliders/HomeSliders';
import Settings from './componts/settings/Settings';
import AddHomeSlider from './componts/home-sliders/AddHomeSlider';
import Invoices from './componts/invoices/Invoices';
import Payouts from './componts/payouts/payouts';
import PayoutCreate from './componts/payouts/PayoutCreate';
import PayoutUpdate from './componts/payouts/PayoutUpdate';
import Roles from './componts/roles/Roles';
import AddRole from './componts/roles/AddRole';
import EditRole from './componts/roles/EditRole';
import Welcome from './componts/welcome/welcome';
import NotAuthorized from './componts/not-authorized/NotAuthorized';
import Sales from './componts/sales/Sales';
import ContactUs from './componts/contact-us/ContactUs';
import Suggestions from './componts/suggestions/Suggestions';
import Refunds from './componts/refunds/Refunds';
import HolidaysPrices from './componts/holidays-prices/holidays-prices';
import Offers from './componts/offers/offers';
import UnitRegistrations from './componts/unit-registrations/UnitRegistrations';
import AddUnitRegistration from './componts/unit-registrations/AddUnitRegistration';
import EditUnitRegistration from './componts/unit-registrations/EditUnitRegistration';

const AppRoutes = () => {
    return (
        <div>
            <Routes>
                <Route element={<ProtectedRoutesAdmin />}>
                    <Route path='/regions' >
                        <Route path='' element={<Regions />} />
                        <Route path='add' element={<AddRegion />} />
                        <Route path='edit/:rogionId' element={<EditRegion />} />
                    </Route>
                    <Route path='/banks' >
                        <Route path='' element={<Banks />} />
                        <Route path='add' element={<AddBank />} />
                        <Route path='edit/:bankId' element={<EditBank />} />
                    </Route>

                    <Route path='/cities' >
                        <Route path='' element={<Cities />} />
                        <Route path='add' element={<AddCity />} />
                        <Route path='edit/:cityId' element={<EditCity />} />
                    </Route>

                    <Route path='/districts' >
                        <Route path='' element={<Districts />} />
                        <Route path='add' element={<AddDistrict />} />
                        <Route path='edit/:districtId' element={<EditDistrict />} />
                    </Route>
                    <Route path='/marketers' >
                        <Route path='' element={<Marketers />} />
                        <Route path='add' element={<AddMarketer />} />
                        <Route path='edit/:marketerId' element={<EditMarketer />} />
                    </Route>
                    <Route path='/employees' >
                        <Route path='' element={<Employees />} />
                        <Route path='add' element={<AddEmployee />} />
                        <Route path='edit/:marketerId' element={<EditEmployee />} />
                    </Route>
                    <Route path='/roles' >
                        <Route path='' element={<Roles />} />
                        <Route path='add' element={<AddRole />} />
                        <Route path='edit/:Id' element={<EditRole />} />
                    </Route>
                    <Route path='/home-city-types' >
                        <Route path='' element={<HomeCityTypes />} />
                        <Route path='add' element={<AddHomeCityType />} />
                        <Route path='edit/:Id' element={<AddHomeSlider />} />
                    </Route>
                    <Route path='/home-sliders' >
                        <Route path='' element={<HomeSliders />} />
                        <Route path='add' element={<AddHomeSlider />} />
                    </Route>
                    <Route path='/unit-registrations' >
                        <Route path='' element={<UnitRegistrations />} />
                        <Route path='add' element={<AddUnitRegistration />} />
                        <Route path='edit/:Id' element={<EditUnitRegistration />} />
                    </Route>

                    <Route path='/properties' >
                        <Route path='' element={<Properties />} />
                        {/* <Route path='add' element={<AddProperty />} /> */}
                        {/* <Route path='edit/:districtId' element={<EditDistrict />} /> */}
                    </Route>

                    <Route path='/add-property'>
                        <Route path='step-1' element={<Step1 />} />
                        <Route path='step-2' element={<Step2 />} />
                        <Route path='step-3' element={<Step3 />} />
                        <Route path='step-4' element={<Step4 />} />
                        <Route path='step-5' element={<Step5 />} />
                        <Route path='step-6' element={<Step6 />} />
                        <Route path='step-7' element={<Step7 />} />
                        <Route path='step-8' element={<Step8 />} />
                        <Route path='step-9' element={<Step9 />} />

                    </Route>

                    <Route path='/guests' >
                        <Route path='' element={<Guests />} />
                        <Route path='add' element={<AddGuest />} />
                        <Route path='change-status/:guestId' element={<ChangeStatus />} />
                        <Route path='edit/:guestId' element={<EditEmail />} />
                    </Route>
                    <Route path='/owners' >
                        <Route path='' element={<Owners />} />
                        <Route path='add' element={<AddOwner />} />
                        <Route path='change-status/:ownerId' element={<ChangeStatusOwner />} />
                        <Route path='edit/:ownerId' element={<EditEmailOwner />} />
                    </Route>
                    <Route path='/chats' >
                        <Route path='' element={<Chats />} />
                        <Route path='messages/:chatId' element={<Showchat />} />

                    </Route>
                    <Route path='/coupons' >
                        <Route path='' element={<Coupons />} />
                        {/* <Route path='messages/:chatId' element={<Showchat />} /> */}

                    </Route>
                    <Route path='/payouts' >
                        <Route path='' element={<Payouts />} />
                        <Route path='create/:id' element={<PayoutCreate />} />
                        <Route path='update/:id' element={<PayoutUpdate />} />

                    </Route>

                    <Route path='/agreement' element={<Agreement />} />
                    <Route path='/reservations' element={<Reservations />} />
                    <Route path='/statistics' element={<Statistics />} />
                    <Route path='/users-rates' element={<UsersRates />} />
                    <Route path='/owners-rates' element={<OwnersRates />} />
                    <Route path='/makan-statistics' element={<MakanStatistics />} />
                    <Route path='/festival-prices' element={<FestivalPrices />} />                    
                    <Route path='/setting' element={<Settings />} />
                    <Route path='/invoices' element={<Invoices />} />
                    <Route path='/sales' element={<Sales />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                    <Route path='/suggestions' element={<Suggestions />} />
                    <Route path='/refunds' element={<Refunds />} />
                    <Route path='/holidays-prices' element={<HolidaysPrices />} />
                    <Route path='/offers' element={<Offers />} />

                    <Route path='/' element={<Welcome />} />
                    <Route path='/403' element={<NotAuthorized />} />
                </Route>
                <Route path='/login' element={<Login />} />
            </Routes>
        </div>
    );
}

export default AppRoutes;
