import React, { useEffect, useState } from 'react'
import { Input, Typography, Button, message, Select, Spin, Form, } from 'antd';
import { get, put } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

const { Title } = Typography;

const EditDistrict = () => {
    const navigate = useNavigate();
    let { districtId } = useParams();


    const [fromloading, setFromLoading] = useState(false);

    const [inputsValue, setinputsValue] = useState({ "name_ar": '', "name_en": "", "city_id": "" });
    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })
    }

    useEffect(() => {
        get(`districts/${districtId}`).then(res => {
            setinputsValue(res.data)
            setFromLoading(true)
        })
    }, []);


    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        put(`districts/${districtId}`, inputsValue).then(res => {
            message.success('تم تعديل بنجاح');
            setLoading(false);
            navigate('/districts')
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading(false);
        })

    };


    const [dataCities, setDataCities] = useState([]);
    const [option, setOption] = useState([]);

    useEffect(() => {
        setOption(dataCities.map(d => ({
            "value": d.id,
            "label": d.name_ar,
        })))

    }, [dataCities]);

    useEffect(() => {
        get('get-all-cities').then(res => {
            setDataCities(res.data)
        })

    }, []);

    const onChange = (value) => {
        console.log(`selected ${value}`);
        setinputsValue({ ...inputsValue, city_id: value })
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    return (
        <div>
            <Title level={2} className='text-main mb-4'> تعديل الحي  </Title>
            {
                fromloading ?
                    <Form
                        onFinish={onFinish}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}

                        initialValues={{
                            "name_ar": inputsValue.name_ar,
                            "name_en": inputsValue.name_en,
                            "city_id": inputsValue.city_id
                        }}
                        autoComplete="off"
                    >
                        <div className='row'>


                            <div className='col-lg-4'>

                                <Form.Item
                                    label='الاسم بالعربي'
                                    name='name_ar'
                                    onChange={handleChange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className='col-lg-4'>

                                <Form.Item
                                    label='الاسم بالانجليزي'
                                    name='name_en'
                                    onChange={handleChange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Item
                                    label='المدينة'
                                    name='city_id'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >

                                    <Select
                                        className='w-100'
                                        showSearch
                                        placeholder="حدد المدينة"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={option}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'هذا الحقل مطلوب!',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </div>

                            <div className='col-lg-4 offset-lg-4'>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 1,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        size='large' block type="primary" htmlType="submit">
                                        {loading ? 'جاري التعديل...' : 'تعديل'}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>



                    </Form>

                    :
                    <div className='text-center'>
                        <Spin size="large" />
                    </div>
            }
        </div>
    )
}

export default EditDistrict
