import React, { useEffect, useState } from "react";
import { Input, Typography, Button, message, Spin, Checkbox, Form } from "antd";
import { get, put } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const { Title } = Typography;

const EditEmail = () => {
  const navigate = useNavigate();
  let { guestId } = useParams();

  const [fromloading, setFromLoading] = useState(false);
  const [inputsValue, setinputsValue] = useState({ email: "dfdfdfdf" });
  const handleChange = e => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    get(`guests/${guestId}`).then(res => {
      setinputsValue({ email: res.data.email });
      setFromLoading(true);
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);

    put(`guests/${guestId}`, inputsValue)
      .then(res => {
        message.success("تم تعديل بنجاح");
        setLoading(false);
        navigate("/guests");
      })
      .catch(err => {
        message.error("حدث خطآ ما, حاول مرة اخري");
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        تعديل البريد الإلكتروني{" "}
      </Title>
      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{ email: inputsValue.email }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                label="البريد الإلكتروني"
                name="email"
                onChange={handleChange}
                rules={[
                  {
                    type: "email",
                    message: "هذا البريد الإلكتروني غير صالح",
                  },
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditEmail;
