import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { del, get, post, put } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination, Switch } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";

const Roles = () => {
  const columns = [
    {
      title: "الرقم ",
      dataIndex: "id",
    },
    {
      title: "الاسم بالعربي",
      dataIndex: "name_ar",
    },
    {
      title: "الاسم بالانجليزي",
      dataIndex: "name",
    },
  ];

  if (checkPermission("update-role") || checkPermission("delete-role")) {
    columns.push(
      {
        title: "تعديل",
        dataIndex: "id",
        render: id => (
          <button className="btn btn-outline-warning btn-sm p-0">
            <Link
              className="color-inherit text-decoration-none py-1 px-2 d-block"
              to={`/roles/edit/${id}`}
            >
              تعديل
            </Link>
          </button>
        ),
      },
      {
        title: "حذف",
        dataIndex: "id",
        render: id => (
          <Popconfirm
            title="حذف العنصر"
            description="هل أنت متأكد من حذف هذه العنصر؟"
            onConfirm={() => confirm(id)}
            onCancel={cancel}
            okText="نعم"
            cancelText="لا"
          >
            <Button danger>حذف</Button>
          </Popconfirm>
        ),
      }
    );
  }

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get("roles?page=1").then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  }, []);

  const handleDelet = id => {
    del(`roles/${id}`);
    let dateFilter = data.filter(item => item.id !== id);
    setdata(dateFilter);
  };

  const confirm = id => {
    console.log(id);
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = e => {
    console.log(e);
    message.error("تم الغاء الامر");
  };

  return (
    <div>
      <div className="row">
        {checkPermission("create-role") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
              <Link
                className="text-decoration-none d-block h-100"
                to="/roles/add"
              >
                <Button type="primary" block>
                {" "}
                إضافة{" "}
            </Button>
              </Link>
          </div>
        )}
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 650,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
