import React, { useState, useEffect } from 'react';
import Steper from '../layoutProperty/Steper';
import { Button, Divider, Form, Input, InputNumber, Radio, Select, Spin, message } from 'antd';
import { get, postFromData } from '../../../../api/axios';
import { Link, useNavigate } from 'react-router-dom';

const Step6 = () => {
    const [loading, setLoading] = useState(false);
    const [fromloading, setFromLoading] = useState(false);
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState({})

    useEffect(() => {
        // return () => {

        if (localStorage.getItem('codeAddProperty')) {
            postFromData('add-property/step-6', { code: localStorage.getItem('codeAddProperty'), ignore_update_level: true }).then(res => {
                setinputsValue(res.data)
                setFromLoading(true)



            })
        } else {
            navigate('/add-property/step-1');
        }
        // }
    }, []);

    const onFinish = (values) => {
        setLoading(true);

        let valuesToSend = [];
        Object.keys(values).forEach((item) => {
            if (recieve_type === 'wallet_account') {
                switch (item) {
                    case 'bank_id':
                    case 'bank_account_name':
                    case 'bank_account_iban':
                    case 'back_account_id':
                        break;
                    default:
                        valuesToSend = [
                            ...valuesToSend,
                            values[item]
                        ];
                        break;
                }
            }
            else {
                switch (item) {
                    case 'wallet_phone':
                        break;
                    default:
                        valuesToSend = [
                            ...valuesToSend,
                            values[item]
                        ];
                        break;
                }
            }
        });


        console.log('valuesToSend:', valuesToSend);
        postFromData('add-property/step-6', { 'code': localStorage.getItem('codeAddProperty'), ...valuesToSend }).then(res => {
            setLoading(false);
            navigate('/add-property/step-7');
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading(false);
        })

    };


    const [recieve_type, setrecieve_type] = useState('bank_account');

    const onChangebank_account = (e) => {
        console.log('radio checked', e);
        setrecieve_type(e);
        // setwallet_account(!e.target.value);
    };

    const [dataBanks, setDataBanks] = useState([]);
    const [optionBanks, setOptionBanks] = useState([]);

    useEffect(() => {
        setOptionBanks(dataBanks.map(d => ({
            "value": d.id,
            "label": d.name_ar,
        })))

    }, [dataBanks]);

    useEffect(() => {
        get('banks').then(res => {
            setDataBanks(res.data.data)
        })
    }, []);



    return (
        <div>
            <div className='add-prop step-6'>
                <Steper current={6} />

                {
                    fromloading ?
                        <Form
                            onFinish={onFinish}
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}

                            initialValues={{}}
                        >

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <Divider orientation="left" className='text-main'> <span className='text-main'> طريقة استخدام مبالغ الحجوزات  </span> </Divider>

                                    <Radio value='bank_account' onChange={() => onChangebank_account('bank_account')} checked={recieve_type === 'bank_account' ? true : false} >  تحويل لحساب بنكي </Radio>
                                    <div className='row mt-3'>
                                        <div className='col-lg-8'>
                                            <Form.Item
                                                label=' البنك'
                                                name='bank_id'
                                                rules={[
                                                    {
                                                        required: recieve_type != 'bank_account' ? false : true,
                                                        message: 'هذا الحقل مطلوب!',
                                                    },
                                                ]}
                                            >

                                                <Select
                                                    disabled={recieve_type == 'bank_account' ? false : true}
                                                    className='w-100'
                                                    showSearch
                                                    placeholder=" اختر البنك"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={optionBanks}
                                                    rules={[
                                                        {
                                                            required: recieve_type != 'bank_account' ? false : true,
                                                            message: 'هذا الحقل مطلوب!',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label=' اسم صاحب الحساب'
                                                name='bank_account_name'
                                                rules={[
                                                    {
                                                        required: recieve_type != 'bank_account' ? false : true,
                                                        message: 'هذا الحقل مطلوب!',
                                                    },
                                                ]}
                                            >
                                                <Input disabled={recieve_type == 'bank_account' ? false : true} />
                                            </Form.Item>
                                            <Form.Item
                                                label=' رقم الايبان'
                                                name='bank_account_iban'
                                                rules={[
                                                    {
                                                        required: recieve_type != 'bank_account' ? false : true,
                                                        message: 'هذا الحقل مطلوب!',
                                                    },
                                                ]}
                                            >
                                                <div className='d-flex p-relative'>
                                                    <Input disabled={recieve_type == 'bank_account' ? false : true} className='w-100' type='number' />
                                                    <span className='badge-sa'>SA</span>
                                                </div>
                                            </Form.Item>
                                            <Form.Item
                                                disabled={recieve_type == 'bank_account' ? false : true}
                                                label=' رقم الحساب'
                                                name='back_account_id'
                                                rules={[
                                                    {
                                                        required: recieve_type != 'bank_account' ? false : true,
                                                        message: 'هذا الحقل مطلوب!',
                                                    },
                                                ]}
                                            >
                                                <Input disabled={recieve_type == 'bank_account' ? false : true} className='w-100' type='number' />
                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <Divider />
                                            <Radio value='wallet_account' onChange={() => onChangebank_account('wallet_account')} checked={recieve_type === 'wallet_account' ? true : false} >  ايداع في محفظة Syc pay الالكترونية (موصى به) </Radio>
                                            <Form.Item
                                                className='mt-3'
                                                label=' رقم الجوال'
                                                name={recieve_type != 'bank_account' ? "wallet_phone" : null}
                                                rules={[
                                                    {
                                                        required: recieve_type == 'bank_account' ? false : true,
                                                        message: 'هذا الحقل مطلوب!',
                                                    },
                                                ]}
                                            >
                                                <Input disabled={recieve_type != 'bank_account' ? false : true} className='w-100' type='number' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div className='row'>
                                <div className='col-lg-4 offset-lg-1'>

                                    <Button
                                        loading={loading}
                                        size='large' block type="primary" htmlType="submit">
                                        {loading ? 'جاري ...' : 'التالي'}
                                    </Button>
                                </div>
                                <div className='col-lg-4 offset-lg-1'>
                                    <Button size='large' block>
                                        <Link className='d-block text-decoration-none' to='/add-property/step-5'> رجوع </Link>
                                    </Button>

                                </div>

                            </div>

                        </Form>
                        :
                        <div className='text-center'>
                            <Spin size="large" />
                        </div>

                }

            </div>
        </div>
    );
}

export default Step6;
