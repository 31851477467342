import LayoutCompoent from './componts/layout/layout';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import './App.scss';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false)
  }, []);
  return (
    <div>
      <BrowserRouter>
        {
          loading ?
            <div className='pre-loading'>

              <Space size="middle">
                <Spin size="large" />
              </Space>
            </div>
            :
            <></>
        }
        <LayoutCompoent />
      </BrowserRouter>
    </div>
  );
}

export default App;
