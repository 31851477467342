import React, { useEffect, useState } from "react";
import { Table, Button, Tabs, message } from "antd";
import { get, post } from "./../../api/axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";
import {
  InstagramOutlined,
  PinterestOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const Properties = () => {
  const [filter, setFilter] = useState("agreed");
  const [data, setData] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const handleStatus = code => {
    setLoadingBtn(true);
    post("properties/change-publish-status", {
      code: code,
      is_agreed: filter === "agreed" ? false : true,
    }).then(res => {
      const someArray = data.filter(item => item.code !== code);
      setData(someArray);
      setLoadingBtn(false);

      message.success("تم تغير الحالة بنجاح");
    });
  };
  const columns = [
    {
      title: (
        <>
          الصورة
          <br />
          كود الوحدة
        </>
      ),
      render: ({ id, image }) => (
        <>
          <img
            width={100}
            height={100}
            src={image}
            style={{ marginBottom: "5px" }}
          />{" "}
          <br />
          {id}
        </>
      ),
    },
    {
      title: (
        <>
          أسم المضيف <br />
          البريد الإلكتروني <br />
          الجوال <br />
          رقم المضيف <br />
          الجنسية <br />
          الإقامة <br />
          رقم الاثبات <br />
          الجنس- تاريخ الميلاد <br />
          العنوان <br />

        </>
      ),
      render: ({ owner, rent_type, commercial_record, address_map, google_commercial_name }) => (
        <>
          {owner.name} <br />
          {owner.email} <br />
          {owner.phone} <br />
          {owner.id_number} <br />
          {owner.nationality.nationality_ar} <br />
          {rent_type} <br />
          {commercial_record} <br />
          {owner.dob_gender} <br />
          {owner.address} <br />


        </>
      ),
    },
    {
      title: (
        <>
          اسم الوحدة <br />
          نوع الوحدة <br />
          كود الوحدة <br />
          المنطقة <br />
          المدينة <br />
          الحي \ القرية <br />
          الأسم على جوجل ماب <br />
          رابط جوجل ماب <br />
          تصنيف الوحدة <br />
        </>
      ),
      render: ({ name, type, google_commercial_name,
        total_rate
        , address_map, code, region, district, city }) => (
        <>
          {name} <br />
          {type} <br />
          {code} <br />
          {region} <br />
          {city} <br />
          {district} <br />

          {address_map} <br />
          {google_commercial_name} <br />
          {total_rate.number} <br />
        </>
      ),
    },
    {
      title: (
        <>
          نوعية الاستخدام <br />
          ضيوف الوحدة<br />
          عدد الصور<br />
          استلام المبالغ <br />
          سياسة الإلغاء <br />
        </>
      ),
      render: ({ guests_type, type, images_count, image_insurance_cancellation
      }) => (
        <>
          {type} <br />
          {guests_type} <br />
          {images_count} <br />
          {image_insurance_cancellation.receive_money} <br />
          {image_insurance_cancellation.cancellation_policy} <br />
        </>
      ),
    },
    {
      title: (
        <>
          <div> نوع مدة الاقامة </div>
          <div> اوقات الوصول والمغادرة </div>
        </>
      ),
      render: ({ arrive_leave }) => (
        <>
          {arrive_leave.map(item => (
            <>
              {item.type} <br />
              {item.arrive_time} <br />
              {item.leave_time} <br />
            </>
          ))}
        </>
      ),
    },
    {
      title: "الاسعار",
      children: [
        {
          title: " الخميس",
          render: ({ prices }) => (
            <>
              {prices.thu.map(item => (
                <>
                  {" "}
                  {item.type} <br /> {item.price}{" "}
                </>
              ))}
            </>
          ),
        },
        {
          title: " الجمعة        ",
          render: ({ prices }) => (
            <>
              {prices.fri.map(item => (
                <>
                  {" "}
                  {item.type} <br /> {item.price}{" "}
                </>
              ))}
            </>
          ),
        },
        {
          title: " السبت        ",
          render: ({ prices }) => (
            <>
              {prices.sat.map(item => (
                <>
                  {" "}
                  {item.type} <br /> {item.price}{" "}
                </>
              ))}
            </>
          ),
        },
        {
          title: " وسط الاسبوع        ",
          render: ({ prices }) => (
            <>
              {prices.rest_of_week.map(item => (
                <>
                  {" "}
                  {item.type} <br /> {item.price}{" "}
                </>
              ))}
            </>
          ),
        },
      ],
    },
    {
      title: (
        <>
          تويتير <br />
          انستغرام <br />
          بينترست
        </>
      ),
      render: ({ instagram_link, pinterest_link, twitter_link }) => (
        <>
          <a
            className="color-inherit text-decoration-none px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={instagram_link}
          >
            <InstagramOutlined style={{ fontSize: "20px" }} />
          </a>
          <br />
          <a
            className="color-inherit text-decoration-none px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={twitter_link}
          >
            <TwitterOutlined style={{ fontSize: "20px" }} />
          </a>
          <br />
          <a
            className="color-inherit text-decoration-none px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={pinterest_link}
          >
            <PinterestOutlined style={{ fontSize: "20px" }} />
          </a>
        </>
      ),
    },
    {
      title: <>{filter === "incomplete" ? "الخطوات" : "تغير الحالة"}</>,
      render: ({ code, completed_steps }) => (
        <>
          {filter === "incomplete" ? (
            <>{completed_steps}</>
          ) : (
            <Button
              onClick={() => handleStatus(code)}
              loading={loadingBtn}
              className="btn btn-warning py-0"
            >
              {filter === "agreed" ? "اخفاء الوحدة" : "عرض الوحدة"}
            </Button>
          )}
        </>
      ),
    },
  ];

  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);
  useEffect(() => {
    get(`properties?status=${filter}`).then(res => {
      setData(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`properties?page=${pageNumber}`).then(res => {
      setData(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  };

  const onChangeTabs = key => {
    setFilter(key);
    setLoading(true);

    get(`properties?status=${key}`).then(res => {
      setData(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  };

  const items = [
    {
      key: "agreed",
      label: "معروضة",
    },
    {
      key: "pending",
      label: "غير معروضة",
    },
    {
      key: "incomplete",
      label: "غير مكتملة",
    },
  ];

  return (
    <div>
      <div className="row">
        {checkPermission("create-property") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
              <Link
                className="text-decoration-none d-block h-100"
                to="/add-property/step-1"
              >
                  <Button type="primary" block>
                  {" "}
                إضافة{" "}
            </Button>
              </Link>
          </div>
        )}
        <div className="col-12 grid-margin stretch-card tabs-section">
          <Tabs
            size="large"
            defaultActiveKey="agreed"
            items={items}
            onChange={onChangeTabs}
          />
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                x: 1550,
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Properties;
