import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    message,
    Spin,
    Form,
    Descriptions,
    Divider,
    Input,
    Switch,
} from "antd";
import { get, newPostFromData, postFromData, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const PayoutUpdate = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [userData, setUserData] = useState({});

    useEffect(() => {
        get(`payouts/${id}`).then((res) => {
            setInputsValue({
                'amount': res.data.data.amount,
                'payout_status': res.data.data.payout_status === 'منفذ' ? 1 : 0,
                'invoice_id': res.data.data.invoice_id
            });

            setUserData(res.data.data)
            setFromLoading(true);
        });
    }, []);

    const handleSwitch = (status) => {
        setInputsValue({ ...inputsValue, 'payout_status': status ? 1 : 0 });
    };

    const handleChange = (e) => {
        setInputsValue({ ...inputsValue, [e.target.id]: e.target.value })

    }



    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        put(`payouts/${id}`, inputsValue )
            .then((res) => {
                message.success("تم تعديل الحوالة بنجاح");
                setLoading(false);
                navigate("/payouts");
            })
            .catch((err) => {
                message.error(err.response.data.message);
                setLoading(false);
            });
    };

    return (
        <div>


            {fromLoading ?

                <>
                    <Descriptions bordered colon>
                        <Descriptions.Item label="نوع الأموال المستلمة">
                            {userData.money_recieve_type}
                        </Descriptions.Item>

                    </Descriptions>
                    <Divider />
                    {
                        userData.account_number != null &&

                        <Descriptions bordered colon>
                            <Descriptions.Item label="رقم الجوال ">
                                {userData.account_number ?? ""}
                            </Descriptions.Item>
                        </Descriptions>
                    }

                    {
                        userData.bank_id != null &&
                        <>

                            <Descriptions colon>
                                <Descriptions.Item label="رقم  البنك">
                                    {userData.bank_id ?? ""}
                                </Descriptions.Item>
                                <Descriptions.Item label="اسم  البنك">
                                    {userData.bank_account_name ?? ""}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions colon>
                                <Descriptions.Item label="رقم  iban">
                                    {userData.bank_account_iban ?? ""}
                                </Descriptions.Item>
                                <Descriptions.Item label="رقم  حساب البنك">
                                    {userData.back_account_id ?? ""}
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    }

                    <Divider />

                    <Form
                        className="wrapper-content"
                        onFinish={onFinish}
                        labelCol={{
                            span: 7,
                        }}
                        wrapperCol={{
                            span: 15,
                        }}
                        initialValues={{
                            amount: inputsValue.amount,
                        }}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <Form.Item
                                onChange={handleChange}
                                    label="المبلغ"
                                    name="amount">
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="col-lg-12">
                                <Form.Item name='payout_status' label='الحالة'>
                                    <Switch onChange={handleSwitch} defaultChecked={inputsValue.payout_status === 1 ? true : false} ></Switch>
                                </Form.Item>

                            </div>

                            <div className="col-lg-4 offset-lg-4">
                                <Form.Item
                                    wrapperCol={{
                                        offset: 1,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        size="large"
                                        block
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {loading ? 'جاري التعديل...' : 'تعديل'}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </>

                : (
                    <div className="text-center">
                        <Spin size="large" />
                    </div>
                )}
        </div>
    );
};

export default PayoutUpdate;
