import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, message } from 'antd';
import { useEffect, useState } from 'react';
import { baseUrl, post, tokenAdmin } from '../../../../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import Steper from '../layoutProperty/Steper';

const useFileUpload = () => {
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const uploadFiles = async () => {
        if (fileList.length === 0) {
            message.error("Please select at least one file to upload.");
            return false;
        }

        setLoading(true);
        const urls = fileList.map(file => file.response ? file.response.url : file.short);

        try {
            await post('add-property/step-4', {
                code: localStorage.getItem('codeAddProperty'),
                urls,
            });
            message.success('Images uploaded successfully.');
            return true;
        } catch (err) {
            message.error(err.response?.data?.message || 'File upload failed.');
            return false;
        } finally {
            setLoading(false);
        }
    };

    return {
        fileList,
        loading,
        handleChange,
        uploadFiles,
    };
};

const Step4 = () => {
    const navigate = useNavigate();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const { fileList, loading, handleChange, uploadFiles } = useFileUpload();

    useEffect(() => {
        const propertyCode = localStorage.getItem('codeAddProperty');
        if (!propertyCode) {
            navigate('/add-property/step-1');
        }
    }, [navigate]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleFinish = async () => {
        const success = await uploadFiles();
        if (success) {
            navigate('/add-property/step-5');
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
            <Steper current={4} />
            <div className='row'>
                <div className="col-lg-4 mb-4">
                    <div className="frm-txt mb-3">
                        قبل إضافة الصور أقراء التعليمات أدناه
                    </div>
                    <ul className="ul-images-desc">
                        <li>يفضل أضافة الصور العرضية وليست الطولية</li>
                        <li>يجب خلو الصور من العبارات أو الشعارات</li>
                        <li>امتداد الصور يجب أن يكون من نوع PNG – JPG – JPEG</li>
                        <li>يفضل إضافة ما لا يقل عن 12 صورة</li>
                        <li>
                            كلما كانت الصورة عالية الوضوح وجذابه كلما زادت فرصة ترشيح
                            الوحدة للضيوف أثناء البحث
                        </li>
                    </ul>
                </div>
                <div className='col-lg-8'>
                    <Upload
                        action={`${baseUrl}upload/store`}
                        listType="picture-card"
                        headers={{ Authorization: `Bearer ${tokenAdmin}` }}
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                    >
                        {fileList.length >= 12 ? null : uploadButton}
                    </Upload>
                    <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={() => setPreviewOpen(false)}
                    >
                        <img
                            alt="example"
                            style={{ width: '100%' }}
                            src={previewImage}
                        />
                    </Modal>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-4 offset-lg-1'>
                    <Button
                        loading={loading}
                        size='large'
                        block
                        type="primary"
                        onClick={handleFinish}
                    >
                        {loading ? 'جاري ...' : 'التالي'}
                    </Button>
                </div>
                <div className='col-lg-4 offset-lg-1'>
                    <Button size='large' block>
                        <Link className='d-block text-decoration-none' to='/add-property/step-3'>
                            رجوع
                        </Link>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Step4;
