import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Tag } from "antd";
import { del, get } from "../../api/axios";
import { Pagination, message } from "antd";
import { Link } from "react-router-dom";

const Suggestions = () => {
  // render: (role) => (role == null ? "" : role.name),
  const columns = [
    {
      title: " الرقم",
      dataIndex: "id",
    },
    {
      title: " الرسالة",
      dataIndex: "message",
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [total, setTotal] = useState(1);

  useEffect(() => {
    get("suggestions").then(res => {
      setdata(res.data.data);
      // setTotal(res.data.meta.total)
      setLoading(false);
    });
  }, []);

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`suggestions?page=${pageNumber}`).then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            {/* <Pagination defaultCurrent={1} className='mt-4 d-flex align-items-center justify-content-center' position='bottomCenter' total={total * 10} onChange={handlePagination} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
