import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Tag } from "antd";
import { del, get } from "../../api/axios";
import { Pagination, message } from "antd";

const FestivalPrices = () => {
  // ant-table-content
  const columns = [
    {
      title: " الرقم   ",
      dataIndex: "id",
    },
    {
      title: "  رقم الطلب  ",
      dataIndex: "request_number",
    },
    {
      title: "  تاريخ الطلب ",
      dataIndex: "request_date",
    },
    {
      title: " اسم العيد   ",
      dataIndex: "name",
    },
    {
      title: " كود الوحدة  ",
      dataIndex: "property_code",
    },
    {
      title: " تاريخ يوم العيد  ",
      dataIndex: "festival_date",
    },

    {
      title: " السعر ",
      children: [
        {
          title: " ليلة",
          dataIndex: "night",
        },
        {
          title: "  فترة صباحية",
          dataIndex: "day_time",
        },
        {
          title: "  فترة مسائية",
          dataIndex: "night_time",
        },
      ],
    },

    {
      title: "  حالة الطلب  ",
      render: ({ status }) => (
        <>
          <Tag color={`${status == "منتهي" ? "success" : "error"}`}>
            {status}
          </Tag>
        </>
      ),
    },
  ];

  const [dataFirst, setdataFirst] = useState([]);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("get-festival-prices").then(res => {
      setdataFirst(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log("dataFirst", dataFirst);
    const newarr = [];
    dataFirst.map(item => {
      const newObj = {};

      // item
      newObj.id = item.id;
      newObj.request_number = item.request_number;
      newObj.request_date = item.request_date;
      newObj.name = item.name;
      newObj.property_code = item.property_code;
      newObj.festival_date = item.festival_date;
      newObj.user_id = item.user_id;

      newObj.night = item.prices.night;
      newObj.day_time = item.prices.day_time;
      newObj.night_time = item.prices.night_time;

      newObj.status = item.status;

      newarr.push(newObj);
    });
    setdata(newarr);
  }, [dataFirst]);

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`get-festival-prices?page=${pageNumber}`).then(res => {
      setdataFirst(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                y: "65vh",
                //     x: 1150,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FestivalPrices;
