import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
const NotAuthorized = () => (
    <Result
        status="403"
        title="403"
        subTitle="انت غير مصرح لك بعرض هذه الصفحة"
        extra={<Link to='/'><Button type="primary"> الرجوع للرئسية </Button></Link>}
    />
);
export default NotAuthorized;