import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { get } from '../../api/axios';

const Showchat = () => {
    let { chatId } = useParams();
    const [data, setdata] = useState([]);

    useEffect(() => {
        get(`chat/${chatId}/messages`).then(res => {
            setdata(res.data)
            console.log('res.data', res.data);
        })

    }, []);

    return (
        <div>
            {/* dkf : {chatId} */}
            <div className="container pb-5">

                <div className="row d-flex ">
                    <div className="col-md-12 col-lg-12 col-xl-12">

                        <div className="card" id="chat2">
                            {/* <div className="card-header d-flex justify-content-between align-items-center p-3">
                                    <h5 className="mb-0">Chat</h5>
                                    <button type="button" className="btn btn-primary btn-sm" data-mdb-ripple-color="dark">Let's Chat
                                        App</button>
                                </div> */}

                            <div className="card-body" data-mdb-perfect-scrollbar="true" style={{ position: "relative", height: 400 }}>
                                {
                                    data.map(item => (
                                        <div className={`d-flex flex-row justify-content-${ item.user_type == 'user' ? 'start' : 'end' } pt-1`}>
                                            <div>
                                                <p className={`small p-2 ms-3 mb-1 rounded-3 ${ item.user_type == 'user' ? 'bg-gray-caht' : 'bg-primary text-white' }`}> {item.message} </p>
                                                <p className="small ms-3 mb-3 rounded-3 text-muted"> {item.date} </p>
                                            </div>
                                        </div>
                                    ))
                                }

                                {/* <div className="d-flex flex-row justify-content-start pt-1">
                                    <div>
                                        <p className="small p-2 ms-3 mb-1 rounded-3 bg-gray-caht ">Hi</p>
                                        <p className="small ms-3 mb-3 rounded-3 text-muted">23:58</p>
                                    </div>
                                </div>


                                <div className="d-flex flex-row justify-content-end mb-4 ">
                                    <div>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">Hiii, I'm good.</p>
                                        <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">00:06</p>
                                    </div>
                                </div> */}



                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Showchat;
