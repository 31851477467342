import React, { useEffect, useState } from 'react'
import { Input, Typography, Button, message, Row, Checkbox, Form, } from 'antd';
import { postFromData } from '../../api/axios';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;






const AddBank = () => {
    const navigate = useNavigate();


    const [inputsValue, setinputsValue] = useState({ "name_ar": '', "name_en": "" });
    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })

    }

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        postFromData('banks', inputsValue).then(res => {
            message.success('تم الإضافة بنجاح');
            setLoading(false);
            navigate('/banks')
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading(false);
        })

    };

    return (
        <div>
            <Title level={2} className='text-main mb-4'> إضافة بنك جديدة </Title>
            <Form
                onFinish={onFinish}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}

                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className='row'>

                    {/* {
                        inputs.map(item => ( */}

                    <div className='col-lg-6'>

                        <Form.Item
                            label='الاسم بالعربي'
                            name='name_ar'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className='col-lg-6'>

                        <Form.Item
                            label='الاسم بالانجليزي'
                            name='name_en'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <div className='col-lg-4 offset-lg-4'>

                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size='large' block type="primary" htmlType="submit">
                                {loading ? 'جاري الإضافة...' : 'إضافة'}
                            </Button>
                        </Form.Item>
                    </div>
                </div>



            </Form>
        </div>
    )
}

export default AddBank
