import React, { useEffect, useState } from 'react';
import { post, postFromData } from '../../../../api/axios';
import { Button, Checkbox, Divider, Form, Input, InputNumber, message, Radio, Space, Spin, Typography } from 'antd';
import Steper from '../layoutProperty/Steper';
import { Link, useNavigate } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const Step8 = () => {
    const [loading, setLoading] = useState(false);
    const [fromloading, setFromLoading] = useState(false);
    const { Paragraph, Text } = Typography;
    const { TextArea } = Input;
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState({})
    const [instructions, setInstructions] = useState('');

    useEffect(() => {
        // return () => {

        if (localStorage.getItem('codeAddProperty')) {
            postFromData('add-property/step-8', { code: localStorage.getItem('codeAddProperty'), ignore_update_level: true }).then(res => {
                setinputsValue(res.data.property)
                setFromLoading(true)
                setInstructions(res.data.property.arrive_instructions)
                console.log('res.data.property', res.data.property);
            })
        } else {
            navigate('/add-property/step-1')
        }
        // }
    }, []);


    const onFinish = (values) => {
        console.log('values', values);
        setLoading(true);

        post('add-property/step-8', { 'code': localStorage.getItem('codeAddProperty'), ...values }).then(res => {
            setLoading(false);
            navigate('/add-property/step-9')
            setinsurance(res.data.property.insurance)
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading(false);
        })

    };
    const [insurance, setinsurance] = useState('no');

   

    const onChangebank_insurance = (e) => {
        console.log('radio checked', e);
        setinsurance(e);
        setinputsValue({ ...inputsValue, insurance: e })
        // setwallet_account(!e.target.value);
    };


    const optionsProperty_permissions = [
        {
            label: 'الحساب وإدارة الحجوزات',
            value: 'account',
        },
        {
            label: 'استقبال الرسائل النصية',
            value: 'receive_sms',
        },
        {
            label: 'التواصل مع الضيوف',
            value: 'contact_guests',
        },
        {
            label: 'التواصل مع مكان',
            value: 'contact_makan',
        },
    ];




    const onChangeInstructions = (e) => {
        // console.log('radio checked', e.target.value);
        setInstructions(e.target.value);
    };


    // const handleDescription = e => {
    //     setinputsValue({ ...inputsValue, 'property': { ...inputsValue.property, 'instructions_description': e.target.value } })
    // }

    return (
        <div className='add-prop step-6'>
            <Steper current={8} />


            {
                fromloading ?
                    <Form
                        onFinish={onFinish}
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}

                        initialValues={{
                            "insurance": inputsValue.insurance,
                            "insurance_amount": inputsValue.insurance_amount,
                            "cancellation_policy": inputsValue.cancellation_policy,
                            "terms": inputsValue.terms ? inputsValue.terms : [[]],
                        }}
                    >

                        <div className='row'>
                            <div className='col-lg-9'>
                                <Divider orientation="left" className='text-main'> <span className='text-main'> مبلغ التأمين   </span> </Divider>
                                <Form.Item
                                    className='mt-3 text-start'
                                    label={<div className='text-start'>بحسب تفضيلاتك ، ادخل مبلغ التأمين أو قم <br /> باختيار (لا يوجد تأمين)</div>}
                                    name='insurance'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >

                                    {/* <Radio.Group onChange={onChange} value={value}> */}
                                    <Radio.Group name='insurance'>

                                        <Radio className='w-100 mb-3' value='yes' onChange={() => onChangebank_insurance('yes')} checked={insurance === 'yes' ? true : false} >  نعم </Radio>
                                        {
                                            insurance == 'yes' ?

                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <Form.Item
                                                            className='w-100'
                                                            label=' مبلغ التأمين'
                                                            name='insurance_amount'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'هذا الحقل مطلوب!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                type='number'
                                                            style={{
                                                                width: '100px',
                                                                display: 'block'
                                                            }} />
                                                        </Form.Item>

                                                    </div>
                                                </div>
                                                :
                                                null}
                                        <Radio className='mb-3' value='no' onChange={() => onChangebank_insurance('no')} checked={insurance === 'no' ? true : false} > لا </Radio>
                                    </Radio.Group>

                                </Form.Item>


                                <Divider orientation="left" className='text-main'> <span className='text-main'> شروط الحجز  </span> </Divider>


                                <Paragraph>
                                    أن كان هناك عدة شروط للحجز ، فيرجى أدخال الشرط الأول ومن ثم قم باختيار ( + إضافة شرط أخر ) واكتب الشرط الثاني وهكذا ...
                                </Paragraph>


                                <Form.List name="terms">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (

                                                <div
                                                    key={key}
                                                    className='d-flex align-items-baseline'
                                                >
                                                    <Form.Item
                                                        className='w-100 mb-1'
                                                        label='شرط'
                                                        {...restField}
                                                        name={[name]}

                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'هذا الحقل مطلوب',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                    <MinusCircleOutlined className='ms-1' onClick={() => remove(name)} />
                                                </div>
                                            ))}
                                            <div className='d-flex justify-content-end'>
                                                <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                                    أضف شرط أخر
                                                </Button>
                                            </div>

                                        </>
                                    )}
                                </Form.List>








                                <Divider orientation="left" className='text-main'> <span className='text-main'> سياسة الإلغاء واسترداد المبالغ  </span> </Divider>

                                {/* <Paragraph>
                                    يمكنك أضافة تعليمات الوصول األن او يمكنك القيام بذلك الحق ًا في منصتك
                                </Paragraph>
                                <Paragraph>
                                    تعليمات الوصول هي تفاصيل إرشادية قد يقدمها بعض المضيفين للضيوف لتسهيل وصولهم ودخولهم مكان اإلقامة، على سبيل المثال وليس الحصر )دخول مكان اإلقامة ذاتي وتجد مفتاح الباب تحت )السجادة(، يمكنك أيضا إرفاق صورة المبنى والمدخل
                                </Paragraph> */}

                                <Form.Item
                                    className='mt-3'
                                    label={<div className='text-start'> فضلا قم باختيار خيار واحد من سياسات الإلغاء <br /> والتعديل والإسترداد</div>}
                                    name='cancellation_policy'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={onChangeInstructions} value={inputsValue.cancellation_policy}>
                                        <Space direction="vertical">
                                            <Radio value={"free"}>
                                                مجاني
                                            </Radio>
                                            <Text type="success" className='success-step8'>
                                                يتيح هذا النوع للضيف حرية إلغاء الحجز واسترداد المبلغ في أي وقت يشاء بشرط أن يكون قبل 3 ساعات من تاريخ الدخول للحجز الذي أكده أو للحجز الذي قام بتعديله.

                                            </Text>
                                            <Radio value={"tolerant"}>
                                                مرن
                                            </Radio>
                                            <Text type="success" className='success-step8'>
                                                يتيح هذا النوع للضيف حرية إلغاء الحجز واسترداد المبلغ قبل وقت الدخول بــــــــ 48 ساعة (موصى به وأكثر تفضيلا)
                                            </Text>
                                            <Radio value={"flexible"}>
                                                متسامح
                                            </Radio>
                                            <Text type="success" className='success-step8'>
                                                يتيح هذا النوع للضيف حرية إلغاء الحجز واسترداد المبلغ قبل وقت الدخول بــــــــ 120 ساعة، أي قبل 5 أيام من تاريخ اليوم المراد إلغاءه

                                            </Text>
                                            <Radio value={"limited"}>
                                                مقيد
                                            </Radio>
                                            <Text type="success">
                                                يقيد هذا النوع من السياسات حرية الضيف في إلغاء الحجز وبالتالي لا يسمح باسترداد المبلغ (غير موصى به)

                                            </Text>

                                        </Space>
                                    </Radio.Group>
                                </Form.Item>

                                {/* {instructions === "custom-instructions" ? (
                                    <Form.Item
                                        name='instructions_description'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'هذا الحقل مطلوب!',
                                            },
                                        ]}
                                    >

                                        <div>
                                            <TextArea className='w-100' value={inputsValue.property.instructions_description} onChange={handleDescription} rows={4} />
                                        </div>

                                    </Form.Item>
                                ) : null} */}

                                {/* <Form.Item
                                    label=' البنك'
                                    name='bank_account_name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item> */}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-4 offset-lg-1'>

                                <Button
                                    loading={loading}
                                    size='large' block type="primary" htmlType="submit">
                                    {loading ? 'جاري ...' : 'التالي'}
                                </Button>
                            </div>
                            <div className='col-lg-4 offset-lg-1'>
                                <Button size='large' block>
                                    <Link className='d-block text-decoration-none' to='/add-property/step-7'> رجوع </Link>
                                </Button>

                            </div>

                        </div>
                    </Form>


                    :
                    <div className='text-center'>
                        <Spin size="large" />
                    </div>

            }
        </div>
    );
}

export default Step8;
