import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { get } from "../../api/axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import { Typography } from "antd";

const Chats = () => {
  const { Title } = Typography;

  const columns = [
    {
      title: "الرسائل",
      dataIndex: "id",
      render: id => <Link to={`messages/${id}`}>مشاهدة الرسائل</Link>,
    },
    {
      title: "رقم الحجز ",
      dataIndex: "reservation_number",
    },
    {
      title: "تاريخ المحادثة ",
      dataIndex: "chat_date",
    },
    {
      title: " وقت المحادثة ",
      dataIndex: "chat_time",
    },
    {
      title: " وقت الانتظار",
      dataIndex: "wait_time",
      render: wait_time => <span>{wait_time} ساعة</span>,
    },
    // {
    //     title: ' ',
    //     dataIndex: '',
    // },
  ];
  const [data, setdata] = useState([]);
  const [report, setreport] = useState({
    confirmed_reservations: "",
    chat_count: "",
    replies_count: "",
    chats_reservations_avg: "",
    replies_reservations_avg: "",
  });
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("chats").then(res => {
      setdata(res.data.list.data);
      setreport(res.data.report);
      setTotal(res.data.list.meta.total);
      setLoading(false);
    });
  }, []);

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`chats?page=${pageNumber}`).then(res => {
      setdata(res.data.list.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="bg-light col-lg-10 p-3 mb-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col">
                    <Title level={5}> الحجوزات المؤكدة: </Title>
                  </div>
                  <div className="col">
                    <Title className="text-main" level={5}>
                      {" "}
                      {report.confirmed_reservations}{" "}
                    </Title>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col">
                    <Title level={5}> عدد الدردشة: </Title>
                  </div>
                  <div className="col">
                    <Title className="text-main" level={5}>
                      {" "}
                      {report.chat_count}{" "}
                    </Title>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col">
                    <Title level={5}> عدد الردود : </Title>
                  </div>
                  <div className="col">
                    <Title className="text-main" level={5}>
                      {" "}
                      {report.replies_count}{" "}
                    </Title>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col">
                    <Title level={5}> متوسط حجوزات الدردشة : </Title>
                  </div>
                  <div className="col">
                    <Title className="text-main" level={5}>
                      {" "}
                      {report.chats_reservations_avg}{" "}
                    </Title>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col">
                    <Title level={5}> متوسط الردود الحجوزات : </Title>
                  </div>
                  <div className="col">
                    <Title className="text-main" level={5}>
                      {" "}
                      {report.replies_reservations_avg}{" "}
                    </Title>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 650,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chats;
