import React, { useEffect, useState } from "react";
import { Input, Typography, Button, message, Checkbox, Form, Spin } from "antd";
import { get, postFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
const { Title, Paragraph } = Typography;

const Agreement = () => {
  const navigate = useNavigate();
  const { TextArea } = Input;

  const optionsSun = {
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      //  '/', // Line break
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video", "audio"],
      ["fullScreen", "showBlocks", "codeView"],
      ["preview", "print"],
      // ['save', 'template'],
    ],
    font: [
      "Arial",
      "Sans-Serif",
      "tahoma",
      "DroidArabicKufiRegular",
      "Georgia",
      "Tahoma",
      "Trebuchet MS",
      "Verdana",
      "Logical",
      "Salesforce Sans",
      "Garamond",
      "Serif",
      "Times New Roman",
      "Helvetica",
    ],
  };

  const [editMode, setEditMode] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [agreementMessage, setAgreementMessage] = useState({
    agreement_text: "",
  });

  useEffect(() => {
    get("agreement").then(res => {
      setAgreementMessage({ agreement_text: res.data });
      setLoadingData(false);
    });
  }, []);

  const handleChange = e => {
    setAgreementMessage({ agreement_text: e });
  };

  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    if (editMode) {
      setLoading(true);

      postFromData("modify-agreement", agreementMessage)
        .then(res => {
          message.success("تم التعديل بنجاح");
          setLoading(false);
          // setEditMode(false);
        })
        .catch(err => {
          message.error(err.response.data.message);
          setLoading(false);
        });
    } else {
      setEditMode(true);
    }
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4 d-flex align-items-center">
        الاتفاقية
        <div class="alert alert-danger p-2 mb-0" role="alert">
          <small
            style={{
              fontSize: "13px",
              display: "flex",
            }}
          >
            المغيرات:
            {`{التاريخ},{إسم المالك},{جنسية المالك},{رقم السجل المدني المالك},{عنوان المالك},{رقم جوال المالك},{البريد الالكتروني للمالك}`}
          </small>
        </div>
      </Title>
      <Form onFinish={onFinish} autoComplete="off">
        <div className="row">
          {!loadingData ? (
            <>
              <div className="col-lg-11 mb-5">
                <SunEditor
                  defaultValue={agreementMessage.agreement_text}
                  showToolbar={false}
                  onChange={handleChange}
                  setDefaultStyle="height: auto; min-height: 300px"
                  setOptions={optionsSun}
                />
              </div>
              <div className="col-lg-4 offset-lg-4">
                {editMode ? (
                  <Button
                    loading={loading}
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    {loading ? "جاري حفظ التعديل..." : "حفظ التعديل"}
                  </Button>
                ) : (
                  <Button htmlType="submit" size="large" block type="primary">
                    هل تريد تعديل الاتفاقية
                  </Button>
                )}
              </div>
            </>
          ) : (
            <div className="text-center">
              <Spin size="large" />
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default Agreement;
