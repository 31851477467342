import React, { useEffect, useState } from 'react'
import { Input, Typography, Button, message, Select, Form, } from 'antd';
import { get, postFromData } from '../../api/axios';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const AddDistrict = () => {
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState(
        {
            "name_ar": '',
            "name_en": "",
            "city_id": "",
        }
    );
    const [dataCities, setDataCities] = useState([]);
    const [option, setOption] = useState([]);

    useEffect(() => {
        setOption(dataCities.map(d => ({
            "value": d.id,
            "label": d.name_ar,
        })))

    }, [dataCities]);

    useEffect(() => {
        get('get-all-cities').then(res => {
            setDataCities(res.data)
        })
    }, []);

    const onChange = (value) => {
        console.log(`selected ${value}`);
        setinputsValue({ ...inputsValue, city_id: value })
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };




    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })

    }

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        postFromData('districts', inputsValue).then(res => {
            message.success('تم الإضافة بنجاح');
            setLoading(false);
            navigate('/districts')
        }).catch(err => {
            message.error(err.response.data.message);
            console.log('err.errors', err.response.data.errors);
            setLoading(false);
        })

    };

    return (
        <div>
            <Title level={2} className='text-main mb-4'> إضافة حي جديدة </Title>
            <Form
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}

                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className='row'>

                    <div className='col-lg-4'>

                        <Form.Item
                            label='الاسم بالعربي'
                            name='name_ar'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className='col-lg-4'>

                        <Form.Item
                            label='الاسم بالانجليزي'
                            name='name_en'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <div className='col-lg-4'>
                        <Form.Item
                            label='المدينة'
                            name='city_id'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Select
                                className='w-100'
                                showSearch
                                placeholder="حدد المدينة"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={option}
                            />

                        </Form.Item>
                    </div>

                    <div className='col-lg-4 offset-lg-4'>

                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size='large' block type="primary" htmlType="submit">
                                {loading ? 'جاري الإضافة...' : 'إضافة'}
                            </Button>
                        </Form.Item>
                    </div>
                </div>



            </Form>
        </div>
    )
}

export default AddDistrict
