import React, { useEffect, useState } from "react";
import { Table, Button, Switch, Divider, Spin } from "antd";
import { del, get, post } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination } from "antd";

const MakanStatistics = () => {
  const [data, setdata] = useState({});
  const [dataTable, setdataTable] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get("get-makan-statistics").then(res => {
      setdata(res.data);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: " عدد المستخدمين ",
      dataIndex: "all",
    },
    {
      title: " عدد الضيوف  ",
      dataIndex: "guests",
    },
    {
      title: " عدد المضيفين   ",
      dataIndex: "owners",
    },
  ];

  return (
    <div className="makan-statistics">
      <h3 className="text-main">أحصائيات مكان</h3>

      <>
        {loading ? (
          <>
            {" "}
            <div className="text-center">
              <Spin size="large" />
            </div>{" "}
          </>
        ) : (
          <>
            <Divider orientation="left">العقارات</Divider>
            <div className="row mt-2">
              {data.properties.map(item => (
                <>
                  <div className="col-lg-4 mb-2 mb-lg-4">
                    <div className="row">
                      <div className="col-7">
                        <div className="label">{item.name} :</div>
                      </div>
                      <div className="col-5">
                        <div className="value">{item.count}</div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <Divider orientation="left"> المستخدمين</Divider>
            <div className="row mt-2">
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد المستخدمين :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.all.all} </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الضيوف :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.guests.all} </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد المضيفين :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.owners.all} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد المستخدمين ( Web ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.all.web} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الضيوف ( Web ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.guests.web} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد المضيفيين ( Web ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.owners.web} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد المستخدمين ( iOS ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.all.ios} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الضيوف ( iOS ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.guests.ios} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد المضيفيين ( iOS ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.owners.android} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد المستخدمين ( Android ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.all.android} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الضيوف ( Android ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.guests.android} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد المضيفيين ( Android ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.users.owners.ios} </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider orientation="left"> الحجوزات</Divider>
            <div className="row mt-2">
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الحجوزات :</div>
                  </div>
                  <div className="col-5">
                    <div className="value"> {data.reservations.total} </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الحجوزات المؤكدة :</div>
                  </div>
                  <div className="col-5">
                    <div className="value">
                      {" "}
                      {data.reservations.status.confirmed}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الحجوزات الملغية:</div>
                  </div>
                  <div className="col-5">
                    <div className="value">
                      {" "}
                      {data.reservations.status.canceled}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الحجوزات ( Web ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value">
                      {" "}
                      {data.reservations.platforms.web}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الحجوزات ( iOS ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value">
                      {" "}
                      {data.reservations.platforms.ios}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الحجوزات ( Android ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value">
                      {" "}
                      {data.reservations.platforms.android}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الحجوزات ( مكان ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value">
                      {" "}
                      {data.reservations.source.user}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* Item */}
              <div className="col-lg-4 mb-2 mb-lg-4">
                <div className="row">
                  <div className="col-7">
                    <div className="label"> عدد الحجوزات ( المضيف ) :</div>
                  </div>
                  <div className="col-5">
                    <div className="value">
                      {" "}
                      {data.reservations.source.owner}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default MakanStatistics;
