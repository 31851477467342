import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { del, get } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";

const HomeSliders = () => {
  const columns = [
    {
      title: "الصورة ",
      dataIndex: "path",
      render: path => (
        <span>
          <img
            src={path}
            alt="alt"
            class="rounded"
            style={{
              height: "100px",
              width: "100px",
              objectFit: "cover",
            }}
          />
        </span>
      ),
    },
    {
      title: "العنوان",
      render({ title }) {
        return <>{title ? title : "-"}</>;
      },
    },
    {
      title: "الوصف",
      render({ description }) {
        return <>{description ? description : "-"}</>;
      },
    },
    {
      title: "الترتيب",
      render({ order }) {
        return <>{order ? order : "-"}</>;
      },
    },
  ];

  if (checkPermission("delete-home-slider")) {
    columns.push({
      title: "حذف",
      dataIndex: "id",
      render: id => (
        <Popconfirm
          title="حذف العنصر"
          description="هل أنت متأكد من حذف هذه العنصر؟"
          onConfirm={() => confirm(id)}
          onCancel={cancel}
          okText="نعم"
          cancelText="لا"
        >
          <Button danger>حذف</Button>
        </Popconfirm>
      ),
    });
  }

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get("home-sliders").then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  }, []);

  const handleDelet = id => {
    del(`home-sliders/${id}`).then(() => {
      let dateFilter = data.filter(item => item.id !== id);
      setdata(dateFilter);
    });
  };

  const confirm = id => {
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = e => {
    message.error("تم الغاء الامر");
  };

  return (
    <div>
      <div className="row">
        {checkPermission("create-home-slider") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
            <Link
                className="text-decoration-none d-block h-100"
                to="/home-sliders/add"
            >
            <Button type="primary" block>
              {" "}
                إضافة{" "}
            </Button>
            </Link>
          </div>
        )}
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 650,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSliders;
