import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Spin,
  Form,
  Upload,
} from "antd";
import { axiosApi1, get, put } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditCity = () => {
  const navigate = useNavigate();
  let { cityId } = useParams();

  const [fromloading, setFromLoading] = useState(false);

  const [inputsValue, setinputsValue] = useState({ name_ar: "", name_en: "" });
  const handleChange = e => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);

  useEffect(() => {
    get(`cities/${cityId}`).then(res => {
      setinputsValue(res.data);
      setFromLoading(true);
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: res.data.image,
        },
      ]);
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);
    let formData = new FormData();
    // console.log('typeof (inputsValue.image)',typeof (inputsValue.image));
    // formData['name_ar'] = 'inputsValue.name_ar'
    formData.append("name_en", inputsValue.name_en);
    formData.append("name_ar", inputsValue.name_ar);
    formData.append("region_id", inputsValue.region_id);
    formData.append("_method", "PUT");
    if (typeof inputsValue.image != "string") {
      formData.append("image", inputsValue.image);
    }
    // for (let item in inputsValue) {
    // }

    axiosApi1
      .post(`cities/${cityId}`, formData)
      .then(res => {
        message.success("تم تعديل بنجاح");
        setLoading(false);
        navigate("/cities");
      })
      .catch(err => {
        message.error(err.response.data.message);
        setLoading(false);
      });

    // put(`cities/${cityId}`, inputsValue).then(res => {
    //     message.success('تم تعديل بنجاح');
    //     setLoading(false);
    //     navigate('/cities')
    // }).catch(err => {
    //     message.error(err.response.data.message);
    //     setLoading(false);
    // })
  };

  const [dataRegions, setDataRegions] = useState([]);
  const [option, setOption] = useState([]);

  useEffect(() => {
    setOption(
      dataRegions.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataRegions]);

  useEffect(() => {
    get("get-all-regions").then(res => {
      setDataRegions(res.data);
    });
  }, []);

  const onChange = value => {
    setinputsValue({ ...inputsValue, region_id: value });
  };
  // const onSearch = (value) => {
  //     console.log('search:', value);
  // };

  // const [fileList, setFileList] = useState([
  //     {
  //       uid: '-1',
  //       name: 'image.png',
  //       status: 'done',
  //       url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     },
  //   ]);

  //   const onChangeUpload = ({ fileList: newFileList }) => {
  //     setFileList(newFileList);
  //     console.log('newFileList',newFileList);
  //   };

  const handleUpload = ({ fileList: newFileList }) => {
    console.log("djklf", newFileList[0].originFileObj);
    setinputsValue({ ...inputsValue, image: newFileList[0].originFileObj });
    setFileList(newFileList);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        تعديل المدينة{" "}
      </Title>
      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            name_ar: inputsValue.name_ar,
            name_en: inputsValue.name_en,
            region_id: inputsValue.region_id,
          }}
          autoComplete="off"
        >
          <div className="row">
            {/* <div className='col-lg-4'>
                        <Upload
                            action="https://test.book-makan.com/api/admin/upload/store"
                            headers={{ "api-key": '61e67458-888d-42b1-9dcb-bd1f89440101' }}
                            listType="picture"
                            maxCount={1}
                            fileList={fileList}
                            onChange={onChangeUpload}


                        >
                            <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                        </Upload>
                    </div> */}

            <div className="col-lg-6">
              <Form.Item
                label="الاسم بالعربي"
                name="name_ar"
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="الاسم بالانجليزي"
                name="name_en"
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="المنطقة"
                name="region_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المنطقة"
                  optionFilterProp="children"
                  onChange={onChange}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={option}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>

            <div className="col-lg-6">
              <Form.Item
                label="الصورة"
                name="image"
              // rules={[
              //     {
              //         required: true,
              //         message: 'هذا الحقل مطلوب!',
              //     },
              // ]}
              >
                <Upload
                  action="https://test.book-makan.com/api/admin/upload/store"
                  headers={{
                    "api-key": "61e67458-888d-42b1-9dcb-bd1f89440101",
                  }}
                  listType="picture"
                  maxCount={1}
                  onChange={handleUpload}
                  fileList={fileList}
                  onPreview={onPreview}
                >
                  <Button icon={<UploadOutlined />}>
                    {" "}
                    اضغظ لتحميل الصورة{" "}
                  </Button>
                </Upload>
              </Form.Item>
            </div>

            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditCity;
