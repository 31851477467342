import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Tag } from "antd";
import { del, get } from "../../api/axios";
import { Pagination, message } from "antd";
import { Link } from "react-router-dom";

const Sales = () => {
  // render: (role) => (role == null ? "" : role.name),
  const columns = [
    {
      title: "   	رقم الحجز",
      dataIndex: "id",
    },
    {
      title: " حالة الحجز",
      dataIndex: "status",
    },
    {
      title: " بيانات الحجز ",
      render: ({ date_of_creation, time_of_creation, cancellation_policy }) => (
        <span>
          تاريخ انشاء الحجز:
          <br />
          {date_of_creation}
          <br />
          وقت الحجز:
          <br />
          {time_of_creation}
          <br />
          سياسة الالغاء:
          <br />
          {cancellation_policy}
          <br />
        </span>
      ),
    },
    {
      title: " بيانات الوصول والمغادرة ",
      dataIndex: "arrives_leaves",
      render: ({ date, arriving_time, leaving_time, type }) => (
        <span>
          تاريخ الوصول والمغادرة
          <br />
          {date}
          <br />
          وقت الوصول:
          <br />
          {arriving_time}
          <br />
          وقت المغادرة:
          <br />
          {leaving_time}
          <br />
          نوع الاقامة:
          <br />
          {type}
          <br />
        </span>
      ),
    },

    {
      title: " بيانات الوحدة ",
      dataIndex: "property",
      render: ({ name, city, district, code }) => (
        <span>
          الوحدة:
          {name}
          <br />
          المدينة:
          {city}
          <br />
          الحي:
          {district}
          <br />
          كود الوحدة:
          <br />
          {code}
          <br />
        </span>
      ),
    },
    {
      title: " بيانات السعر والدفع ",
      dataIndex: "prices",
      render: ({
        total_service,
        totalPrice,
        coupon_amount,
        offers_amount,
        subTotal,
        commission_amount,
        type,
      }) => (
        <span>
          {subTotal}
          <br />
          الخصم (عرض)
          <br />
          {offers_amount} ريال
          <br />
          الخصم (كود خصم)
          <br />
          {coupon_amount} ريال
          <br />
          {/* مبيانات السعر والدفع:
                    <br />
                    {subTotal}
                    <br /> */}
          رسوم الخدمة:
          <br />
          {total_service} ريال
          <br />
          المجموع:
          <br />
          {totalPrice} ريال
          <br />
        </span>
      ),
    },
    {
      title: "   بيانات المضيف  ",
      dataIndex: "owner",
      render: ({ name, phone, email, code }) => (
        <span>
          الاسم:
          {name}
          <br />
          الجوال:
          {phone}
          <br />
          البريد الإلكتروني:
          {email}
        </span>
      ),
    },

    {
      title: (
        <>
          المدفوع من الضيف
          <br />
          صافي مبيعات مكان (الرسوم)
          <br />
          ضريبة القيمة المضافة(الرسوم)
          <br />
          (الرسوم شامل الضريبة)
          <br />
          مبيعات المضيف
        </>
      ),
      dataIndex: "prices",
      render: ({
        totalPrice,
        service_amount,
        service_tax,
        total_service,
        total_price_without_service,
      }) => (
        <span>
          {totalPrice}
          <br />
          {service_amount}
          <br />
          {service_tax}
          <br />
          {total_service}
          <br />
          {total_price_without_service}
        </span>
      ),
    },
    {
      title: (
        <>
          مبيعات المضيف
          <br />
          صافي مبيعات مكان (العمولة)
          <br />
          ضريبة القيمة المضافة (العمولة)
          <br />
          (العمولة شامل الضريبة)
          <br />
          صافي مبيعات المضيف
        </>
      ),
      dataIndex: "prices",
      render: ({
        total_price_without_service,
        commission_amount,
        commission_tax_amount,
        total_commission,
        total_price_without_service_commission,
      }) => (
        <span>
          {total_price_without_service}
          <br />
          {commission_amount}
          <br />
          {commission_tax_amount}
          <br />
          {total_commission}
          <br />
          {total_price_without_service_commission}
        </span>
      ),
    },
    {
      title: (
        <>
          صافي مبيعات مكان (الرسوم و العمولة )
          <br />
          ضريبة القيمة المضافة(الرسوم و العمولة)
          <br />
          (الرسوم و العمولة شامل الضريبة)
        </>
      ),
      dataIndex: "prices",
      render: ({
        commission_service_amount,
        commission_service_tax,
        commission_service_total,
      }) => (
        <span>
          {commission_service_amount}
          <br />
          {commission_service_tax}
          <br />
          {commission_service_total}
        </span>
      ),
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("invoices").then(res => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const handlePagination = pageNumber => {
    setLoading(true);

    get(`invoices?page=${pageNumber}`).then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              // loading={loading}
              pagination={false}
              bordered
              scroll={{
                x: 1750,
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
